import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCardDatesAsync } from './RodLiftAnalysisService';
import { getCardColor } from '../../utilities/CardColorGenerator';

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

interface CardDateItemState {
  date: string;
  cardTypeId: string;
  cardTypeName: string;
  isSelected: boolean;
  id: number;
  color: RGBColor;
}

interface CardDateListState {
  values: CardDateItemState[];
  selectedCardDates: string[];
  colorIds: number[];
  assetId: string;
  isLoading: boolean;
}

const fetchRodLiftAnalysisCardDatesAsync = createAsyncThunk(
  'RodLiftAnalysis/fetchCardDates',
  async (assetId: string) => {
    const response = await fetchCardDatesAsync(assetId);

    return response;
  },
);

const initialState: CardDateListState = {
  values: [],
  selectedCardDates: [],
  colorIds: [],
  assetId: '',
  isLoading: false,
};

const rodLiftAnalysisCardDateSlice = createSlice({
  name: 'RodLiftAnalysis/CardDate',
  initialState,
  reducers: {
    toggleSelected(state, action: PayloadAction<string>) {
      const date = action.payload;

      if (!state.selectedCardDates.includes(date) && state.selectedCardDates.length >= 50) {
        alert('You can add only 50 cards to the chart.');
        return;
      }

      state.values.forEach((x) => {
        if (x.date == date) {
          if (x.isSelected) {
            const indexToRemove = state.selectedCardDates.findIndex((date) => date == x.date);
            state.selectedCardDates.splice(indexToRemove, 1);
            state.colorIds.splice(indexToRemove, 1);
          } else {
            const colorIndex = getNextColorId(state.colorIds);
            x.color = getCardColor(colorIndex);
            state.colorIds.push(colorIndex);
            state.selectedCardDates.push(x.date);
          }

          x.isSelected = !x.isSelected;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRodLiftAnalysisCardDatesAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRodLiftAnalysisCardDatesAsync.fulfilled, (_, action) => {
      const assetId = action.meta.arg;

      if (action?.payload?.values === undefined) {
        return;
      }

      const values = action.payload?.values.map(
        (x) =>
          ({
            date: x.date,
            cardTypeId: x.cardTypeId,
            cardTypeName: x.cardTypeName,
            id: x.id,
            isSelected: false,
          }) as CardDateItemState,
      );

      return {
        ...initialState,
        assetId: assetId,
        values: values,
        isLoading: false,
      };
    });
    builder.addCase(fetchRodLiftAnalysisCardDatesAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

// Helper Methods
const getNextColorId = (colorIds: number[]) => {
  if (colorIds.length == 0) {
    return 1;
  }

  const expectedValues = Array.from({ length: colorIds.length + 1 }, (_, i) => i + 1);
  const nextValue = expectedValues.find((x) => !colorIds.includes(x)) || expectedValues.length + 1;

  return nextValue;
};

export { CardDateItemState, fetchRodLiftAnalysisCardDatesAsync };
export const { toggleSelected } = rodLiftAnalysisCardDateSlice.actions;
export default rodLiftAnalysisCardDateSlice.reducer;

import React from 'react';

const Unlinked = ({ color = '#B8C5CC' }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_6515_31892)'>
      <path
        d='M11.5129 14.693L10.3344 15.8715C8.7072 17.4987 6.06901 17.4987 4.44182 15.8715C2.81464 14.2443 2.81464 11.6061 4.44182 9.97896L5.62034 8.80045M16.2269 9.97896L17.4054 8.80045C19.0326 7.17326 19.0326 4.53507 17.4054 2.90789C15.7783 1.2807 13.1401 1.2807 11.5129 2.90789L10.3344 4.0864M8.00697 12.3063L13.8403 6.47302'
        stroke={color}
        stroke-width='1.66667'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_6515_31892'>
        <rect width='20' height='20' fill='white' transform='translate(0.923279 0.222656)' />
      </clipPath>
    </defs>
  </svg>
);

export default Unlinked;

import React from 'react';
import '../../rod-lift-analysis/CardDateList/CardDateList.css';
import WellTestItem from '../WellTestItem/WellTestItem';
import { useEffect } from 'react';
import { fetchESPAnalysisAsync } from '../ESPAnalysisSlice';
import { fetchEspAnalysisCardCoordinatesAsync } from '../ESPCardCoordinatesSlice';
import { WellTestItemState, fetchESPAnalysisWellTestAsync, toggleSelected } from '../WellTestSlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';
import { FetchWellTestResponse } from '../ESPAnalysisService';
import { FixedSizeList as List } from 'react-window';
import Loader from '../../common/Loader/Loader';

interface WellTestListProps {
  dateRangeFilteredCardDates: WellTestItemState[] | null;
  assetId: string;
}

const WellTestList = (props: WellTestListProps) => {
  const store = useAppSelector((state) => state.espAnalysisWellTest);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeWellTestStore = async () => {
      dispatch(fetchESPAnalysisWellTestAsync({ assetId: props.assetId })).then((response) => {
        const payload = response.payload as FetchWellTestResponse | undefined;
        if (payload && payload.values.length > 0) {
          const firstCardDate = payload.values[0].date;
          const firstFetch = true;
          onClickActionHandler(1, firstCardDate, firstFetch);
        }
      });
    };

    initializeWellTestStore();
  }, [props.assetId]);

  const onClickActionHandler = (id: number, date: string, firstFetch = false) => {
    let fetchData = true;

    // Don't fetch if toggling off card item.
    if (!firstFetch && store.values?.find((x) => x.id == id)?.isSelected == true) {
      fetchData = false;
    }

    dispatch(toggleSelected(id));

    if (fetchData) {
      dispatch(fetchESPAnalysisAsync({ assetId: props.assetId, dateTime: date }));
      dispatch(fetchEspAnalysisCardCoordinatesAsync({ assetId: props.assetId, cardDate: date }));
    }
  };

  const visibleWellTestItems = props.dateRangeFilteredCardDates ?? store.values;

  const Row = ({ index, style }) => (
    <WellTestItem
      id={visibleWellTestItems[index].id}
      key={index}
      date={visibleWellTestItems[index].date}
      name={visibleWellTestItems[index].analysisTypeName}
      isSelected={visibleWellTestItems[index].isSelected}
      onClickAction={onClickActionHandler}
      color={visibleWellTestItems[index].color}
      style={style}
    />
  );

  return (
    <div>
      {store.loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <List height={465} itemCount={visibleWellTestItems.length} itemSize={55} width='100%'>
          {Row}
        </List>
      )}
    </div>
  );
};

export default WellTestList;

import './Breadcrumb.scss';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import Dropdown, { Asset } from '../../common/Dropdown/Dropdown';
import { updateSelectedAsset } from '../AssetGroupsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchNotificationsAsync } from '../NotificationsSlice';
import { setIndustryApplicationId } from '../../asset-analysis/AssetAnalysisSlice';
import { fetchDataHistoryTrendLibraryAsync } from './../../data-history/TrendLibrary/TrendLibrarySlice';
import Loader from '../../common/Loader/Loader';

const Breadcrumb: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentAssetURLPath = location.pathname.split('/')[2];
  const groupName = useAppSelector((state) => state.assetGroups?.selectedGroup?.assetGroupName);

  const selectedGroup = useAppSelector((state) => state.assetGroups?.selectedGroup);
  const selectedAssetId = useAppSelector((state) => state.assetGroups?.selectedAssetId);

  const handleAssetIdChange = (asset: Asset) => {
    const assetId = asset.name;
    dispatch(fetchDataHistoryTrendLibraryAsync({ assetId: asset.id, groupName: groupName }));
    dispatch(
      updateSelectedAsset({
        groupId: selectedGroup?.id,
        assetId: assetId,
        searchString: undefined,
        assetIdGuid: asset.id,
      }),
    );
    if (selectedGroup && selectedGroup.assetGroupName) {
      const formattedGroupName = selectedGroup.assetGroupName.split(' ').join('_');
      navigate(
        `/asset/${currentAssetURLPath}/${selectedGroup.assets.find((asset) => asset.assetName === assetId)
          ?.assetId}/${formattedGroupName}`,
      );
    }

    const selectedAsset = selectedGroup?.assets.filter((asset) => asset.assetName === assetId)[0];
    dispatch(fetchNotificationsAsync({ type: 'asset', id: selectedAsset?.assetId }));
    const industryApplicationId = selectedGroup?.assets.find((a) => a.assetName == assetId)?.industryApplicationId;
    dispatch(setIndustryApplicationId({ industryApplicationId: industryApplicationId }));
  };

  const handleGroupNameClick = () => {
    dispatch(
      updateSelectedAsset({
        groupId: selectedGroup?.id,
        assetId: undefined,
        searchString: undefined,
        assetIdGuid: undefined,
      }),
    );
    if (selectedGroup && selectedGroup.assetGroupName) {
      dispatch(fetchNotificationsAsync({ type: 'group', id: selectedGroup?.assetGroupName }));
      navigate(`/group/status/${encodeURIComponent(selectedGroup.assetGroupName)}`);
    }
  };

  return selectedGroup ? (
    <div className='breadcrumb'>
      <div className='group'>
        <button
          data-testid='group-btn'
          className='group-btn'
          onClick={() => {
            handleGroupNameClick();
          }}
        >
          {selectedGroup?.assetGroupName}
        </button>
      </div>
      {selectedGroup && <div className='breadcrumb-separator'>{'\\'}</div>}
      <div className='assets'>
        {selectedGroup && (
          <Dropdown
            assets={selectedGroup.assets.map((asset) => ({ name: asset.assetName, id: asset.assetId }))}
            showEyeIcon={false}
            defaultOption={selectedAssetId || undefined}
            onChange={handleAssetIdChange}
          />
        )}
      </div>
    </div>
  ) : (
    <div className='loader-relative'>
      <Loader left={12} top={4} height={6} />
    </div>
  );
};

export default Breadcrumb;

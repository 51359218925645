import React, { useEffect, useState, useMemo } from 'react';
import UIIconButtonToolbar from '../../common/UIIconButtonToolbar/UIIconButtonToolbar';
import '../../rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis.css';
import LayerThree from '../../../images/layer-three-01.svg';
import DotPoint from '../../../images/dot-point.svg';
import Line from '../../../images/line-02.png';
import './TrendGroupLabel.scss';
import UIEyeButton from '../../common/UIButtons/UIEyeButton';
import Tooltip from '../../common/tooltip/ToolTip';
import CardTrendOptions from '../../common/CardTrendOptions/CardTrendOptions';
import TrendLibrary from '../../data-history/TrendLibrary/TrendLibrary';
import CardHeader from '../../common/CardHeader/CardHeader';
import TrendGroupView from '../../trend-group-view/TrendGroupView';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import TrendGroupTimeline from '../../common/timeline/TrendGroupTimeline';
import { TrendGroupTimelineProps } from '../AssetAnalysis';
import TimelineDatepicker from '../../common/timeline/TimelineDatepicker';
import { overlayCoordinates } from '../../data-history/TrendLibrary/TrendCoordinateSlice.tsx';
import TrendGroupChart from '../../asset-analysis/TrendGroupLabel/TrendGroupChart';
import { clearSearchFilter } from '../../data-history/TrendLibrary/TrendLibrarySlice';
import TimelineIcon from '../../common/timeline/TimelineIcon';
import { setActiveChartKey } from './TrendGroupLabelSlice';
import TrendCustomViews, { TrendDefaultView as TrendDefaultView } from './TrendCustomViews';
import { fetchGetDefaultTrendsDataAsync } from './../../../features/data-history/TrendLibrary/DefaultTrendsSlice.tsx';
import { useParams } from 'react-router-dom';
import { isExistInTrendLibrary } from '../../data-history/TrendLibrary/TrendLibraryUtility.ts';
import { trendCoordinateSlice } from '../../data-history/TrendLibrary/TrendCoordinateSlice';
import Loader from '../../common/Loader/Loader';
import { useLocation } from 'react-router-dom';

interface UrlParams {
  assetId: string;
}

interface ChartInfoType {
  key: number;
  name: string;
  active: boolean;
  image: string;
  tooltip: string;
  svgNode?: JSX.Element;
}

interface TrendGroupLabelProps {
  timelineProps: TrendGroupTimelineProps;
}

const TrendGroupLabel = ({ timelineProps }: TrendGroupLabelProps) => {
  const overlayChartCoordinates = useAppSelector(overlayCoordinates);
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    isDateLinked,
    showDateLinkIcon,
    setStartDate,
    setEndDate,
    onClickLinkIcon,
    datepickerOnChange,
    setMaxDate,
    setMinDate,
  } = timelineProps;
  const { assetId } = useParams<keyof UrlParams>() as UrlParams;

  const selectedAlarmLimits = useAppSelector((state) => state.trendGroupLabel.AlarmLimits);
  const trendLoader = useAppSelector(trendCoordinateSlice).loading;
  const isLoadingGroupDefaultTrends = useAppSelector((state) => state.customViewDataSlice.groupLoading);
  const isLoadingOverlayDefaultTrends = useAppSelector((state) => state.customViewDataSlice.overlayLoading);
  const trendCustomViews = useAppSelector((state) => state.trendGroupLabel.customViews);
  const selectedView = useAppSelector((state) => state.trendGroupLabel.selectedView);
  const trendLibrary = useAppSelector((state) => state.trendLibrary);

  const [selectedQuickDate, setSelectedQuickDate] = useState<string>();
  const location = useLocation();
  // const [prevMinDate, setPrevMinDate] = useState<Date | null>(null);
  // const [prevMaxDate, setPrevMaxDate] = useState<Date | null>(null);

  const svgTogglePrimary = (
    <svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.66663 9.22312L9.70182 13.2407C9.81114 13.2954 9.86579 13.3227 9.92313 13.3335C9.97391 13.343 10.026 13.343 10.0768 13.3335C10.1341 13.3227 10.1888 13.2954 10.2981 13.2407L18.3333 9.22312M1.66663 13.3898L9.70182 17.4074C9.81114 17.462 9.86579 17.4894 9.92313 17.5001C9.97391 17.5097 10.026 17.5097 10.0768 17.5001C10.1341 17.4894 10.1888 17.462 10.2981 17.4074L18.3333 13.3898M1.66663 5.05645L9.70182 1.03886C9.81114 0.984196 9.86579 0.956866 9.92313 0.94611C9.97391 0.936583 10.026 0.936583 10.0768 0.94611C10.1341 0.956866 10.1888 0.984196 10.2981 1.03886L18.3333 5.05645L10.2981 9.07405C10.1888 9.12871 10.1341 9.15604 10.0768 9.16679C10.026 9.17632 9.97391 9.17632 9.92313 9.16679C9.86579 9.15604 9.81114 9.12871 9.70182 9.07405L1.66663 5.05645Z'
        stroke='#0094BD'
        stroke-width='1.4'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
  const svgToggleSecondary = (
    <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5 7.22298L6.5 7.22298M16.5 2.22298L6.5 2.22298M16.5 12.223L6.5 12.223M3.16667 7.22298C3.16667 7.68322 2.79357 8.05631 2.33333 8.05631C1.8731 8.05631 1.5 7.68322 1.5 7.22298C1.5 6.76274 1.8731 6.38965 2.33333 6.38965C2.79357 6.38965 3.16667 6.76274 3.16667 7.22298ZM3.16667 2.22298C3.16667 2.68322 2.79357 3.05632 2.33333 3.05632C1.8731 3.05632 1.5 2.68322 1.5 2.22298C1.5 1.76274 1.8731 1.38965 2.33333 1.38965C2.79357 1.38965 3.16667 1.76274 3.16667 2.22298ZM3.16667 12.223C3.16667 12.6832 2.79357 13.0563 2.33333 13.0563C1.8731 13.0563 1.5 12.6832 1.5 12.223C1.5 11.7627 1.8731 11.3896 2.33333 11.3896C2.79357 11.3896 3.16667 11.7627 3.16667 12.223Z'
        stroke='#F7F9F9'
        stroke-width='1.4'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
  const [activeChart, setActiveChart] = useState<ChartInfoType>({
    key: 1,
    name: 'view-toggle-2',
    active: true,
    image: DotPoint,
    tooltip: 'Trend group view',
    svgNode: svgToggleSecondary,
  });
  const uiIconButtons = [
    {
      key: 0,
      name: 'view-toggle-1',
      active: false,
      image: LayerThree,
      tooltip: 'Trend view',
      svgNode: svgTogglePrimary,
    },
    {
      key: 1,
      name: 'view-toggle-2',
      active: true,
      image: DotPoint,
      tooltip: 'Trend group view',
      svgNode: svgToggleSecondary,
    },
  ];
  const [selectedCardTrends, setSelectedCardTrends] = useState(
    Array.of<{ id: number; label: string; active: boolean }>({ id: 1, label: 'Alarm Limits', active: true }),
  );

  const getselectedView = () => {
    if (selectedView && selectedView.viewName) {
      return selectedView;
    } else {
      if (trendCustomViews && trendCustomViews.values && trendCustomViews.values.length) {
        return trendCustomViews?.values?.find((view) => view.isSelected) ?? trendCustomViews.values[0];
      }
    }
  };

  function buttonClicked(id: number) {
    const newArray = [...uiIconButtonState];
    newArray.forEach((button) => {
      if (id == button.key) {
        button.active = true;
        setActiveChart(button);
      } else {
        button.active = false;
      }
    });
    setActive(newArray);

    if (defaultTrendViews && Array.isArray(defaultTrendViews.values)) {
      const view = getselectedView();
      const viewId = view?.viewId?.toString();

      if (activeChart.key !== id) {
        if (activeChart.key === 1) {
          if (trendLibrary.ischeckBoxUpdated) return;
          dispatch(
            fetchGetDefaultTrendsDataAsync({
              assetId,
              viewId: viewId,
              startDate: startDate?.toISOString(),
              endDate: endDate?.toISOString(),
              isOverlay: 1,
            }),
          );
        } else {
          dispatch(
            fetchGetDefaultTrendsDataAsync({
              assetId,
              viewId: viewId,
              startDate: startDate?.toISOString(),
              endDate: endDate?.toISOString(),
            }),
          );
        }
      }
      return;
    }
  }

  const [uiIconButtonState, setActive] = useState(uiIconButtons);
  const dispatch = useAppDispatch();

  const onGraphClickHandle = () => {
    dispatch(clearSearchFilter());
  };

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isFullScreen) {
      htmlElement.classList.add('fullscreen');
    } else {
      htmlElement.classList.remove('fullscreen');
    }
  }, [isFullScreen]);

  const defaultTrendViews: TrendDefaultView[] = trendCustomViews;

  const [selectedDefaultView, setSelectedDefaultView] = useState<TrendDefaultView[]>([]);

  const handleDateChange = (startDate: Date, endDate: Date) => {
    const updatedSelectedDefaultView = selectedDefaultView.map((view) => {
      if (Array.isArray(view.values)) {
        return {
          ...view,
          values: view.values.map((value) => ({
            ...value,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          })),
        };
      } else {
        return view;
      }
    });
    setSelectedDefaultView(updatedSelectedDefaultView);
  };

  function compareDates(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate() &&
      d1.getHours() === d2.getHours() &&
      d1.getMinutes() === d2.getMinutes()
    );
  }
  useEffect(() => {
    const sDate = new Date();
    const day = new Date(sDate);
    let isDefaultQuickDateSet = false;
    if (selectedQuickDate === '1D' && !isDefaultQuickDateSet) {
      day.setDate(sDate.getDate() - 1);
      day.setTime(day.getTime() + day.getTimezoneOffset() * 60 * 1000);
      if (!compareDates(day, minDate)) {
        setSelectedQuickDate('');
      }
    } else if (selectedQuickDate === '1W') {
      day.setDate(sDate.getDate() - 7);
      day.setTime(day.getTime() + day.getTimezoneOffset() * 60 * 1000);
      if (!compareDates(day, minDate)) {
        setSelectedQuickDate('');
      }
    } else if (selectedQuickDate === '1MO') {
      day.setDate(sDate.getDate() - 30);
      day.setTime(day.getTime() + day.getTimezoneOffset() * 60 * 1000);
      if (!compareDates(day, minDate)) {
        setSelectedQuickDate('');
      }
    } else {
      if (!selectedQuickDate) {
        day.setDate(sDate.getDate() - 1);
        if (compareDates(day, minDate)) {
          setSelectedQuickDate('1D');
          isDefaultQuickDateSet = true;
        }
      }
    }
  }, [minDate, maxDate, startDate, endDate, location.pathname]);

  useEffect(() => {
    setSelectedQuickDate('1D');
  }, []);

  useEffect(() => {
    dispatch(setActiveChartKey(activeChart.key)); // Dispatch the action to update activeChartKey
  }, [dispatch, activeChart.key]);

  const chartData = useMemo(() => {
    const chartCoordinates = overlayChartCoordinates.chart1.filter((trend) =>
      isExistInTrendLibrary(trendLibrary, trend),
    );
    return chartCoordinates;
  }, [overlayChartCoordinates.chart1]);
  return (
    <div className={isFullScreen ? 'full-screen' : ''}>
      <div className={`card-viewer ${isFullScreen ? 'card-viewer-full-screen-override' : ''}`}>
        <CardHeader value='History graph' icon='fullScreen' isFullScreen={isFullScreen} onClick={handleClick} />
        <div className='card-header-divider' />
        <div className='card-viewer-body' data-testid='trend-group-view'>
          <div className='card-viewer-icons'>
            <UIIconButtonToolbar uiIconButtons={uiIconButtonState} buttonClicked={buttonClicked} />
            <TrendCustomViews
              startDate={startDate}
              endDate={endDate}
              views={[defaultTrendViews]}
              onDateChange={handleDateChange}
            />
            <div className='card-viewer-button-container w-full'>
              <div className='card-viewer-date-picker-container w-full'>
                <div className='card-viewer-quick-date'>
                  <div className={selectedQuickDate === '1D' ? 'quick-date-selected' : 'quick-date'}>
                    <span
                      onClick={() => {
                        const startDate = new Date();
                        startDate.setDate(startDate.getDate() - 1);

                        const value = {
                          startDate: startDate,
                          endDate: new Date(),
                        };
                        datepickerOnChange(value, minDate, maxDate, setMinDate, setMaxDate);
                        setSelectedQuickDate('1D');
                      }}
                    >
                      1D
                    </span>
                  </div>
                  <div className={selectedQuickDate === '1W' ? 'quick-date-selected' : 'quick-date'}>
                    <span
                      onClick={() => {
                        const startDate = new Date();
                        startDate.setDate(startDate.getDate() - 7);

                        const value = {
                          startDate: startDate,
                          endDate: new Date(),
                        };
                        datepickerOnChange(value, minDate, maxDate, setMinDate, setMaxDate);
                        setSelectedQuickDate('1W');
                      }}
                    >
                      1W
                    </span>
                  </div>
                  <div className={selectedQuickDate === '1MO' ? 'quick-date-selected' : 'quick-date'}>
                    <span
                      onClick={() => {
                        const startDate = new Date();
                        startDate.setDate(startDate.getDate() - 30);

                        const value = {
                          startDate: startDate,
                          endDate: new Date(),
                        };
                        datepickerOnChange(value, minDate, maxDate, setMinDate, setMaxDate);
                        setSelectedQuickDate('1MO');
                      }}
                    >
                      1MO
                    </span>
                  </div>
                </div>
                <img src={Line} className='card-calendar-margin' />
                {showDateLinkIcon && (
                  <div onClick={() => onClickLinkIcon(1)}>
                    <TimelineIcon isDateLinked={isDateLinked} />
                  </div>
                )}
                <div className='flex date-picker-input card-calendar-width card-calendar'>
                  <TimelineDatepicker {...timelineProps} />
                </div>
              </div>

              <img src={Line} className='line-icon' />
              {activeChart?.key !== 1 && activeChart?.key !== 0 && (
                <Tooltip content='Curve view options' direction='top'>
                  <CardTrendOptions
                    label='View Options'
                    options={selectedCardTrends}
                    buttonContent={
                      <div className='card-viewer-button'>
                        <UIEyeButton type='eye' />
                      </div>
                    }
                    onOptionChange={(id, value) => {
                      setSelectedCardTrends((selectedCardTrends) => {
                        const newSelectedCardTrends = [...selectedCardTrends];
                        const index = newSelectedCardTrends.findIndex((x) => x.id == id);
                        newSelectedCardTrends[index].active = value;
                        return newSelectedCardTrends;
                      });
                    }}
                  />
                </Tooltip>
              )}

              <Tooltip content='Trend Library' direction='top'>
                {activeChart?.key !== 1 && (
                  <TrendLibrary
                    endDate={endDate}
                    startDate={startDate}
                    buttonContent={
                      <div className='card-viewer-button' onClick={onGraphClickHandle}>
                        <UIEyeButton type='graph' />
                      </div>
                    }
                  />
                )}
              </Tooltip>
            </div>
          </div>
          {activeChart?.key === 1 ? (
            <TrendGroupView startDate={startDate} endDate={endDate} />
          ) : isLoadingGroupDefaultTrends || trendLoader || isLoadingOverlayDefaultTrends ? (
            <div className='trend-group-chart'>
              <Loader />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
              }}
            >
              <TrendGroupChart
                selectedAlarmLimits={selectedAlarmLimits}
                showAlarmLimits={selectedCardTrends[0].active}
                trendGroup={chartData}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
          )}
          <TrendGroupTimeline
            minDate={minDate}
            maxDate={maxDate}
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            onChangeDateRange={(startDate, endDate) => {
              datepickerOnChange({ startDate, endDate }, minDate, maxDate, setMinDate, setMaxDate, false);
            }}
            onChangeSelectedRange={(startDate, endDate) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            isFullScreen={isFullScreen}
          />
        </div>
      </div>
    </div>
  );
};

export default TrendGroupLabel;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import './Timeline.scss';
import { CardDateItemState } from '../../rod-lift-analysis/CardDateSlice';
import { WellTestItemState } from '../../esp-analysis/WellTestSlice';
import { WellTestData } from '../../gl-analysis/GLAnalysisWellTestSlice';
import { renderCardDateLines, useDragEventListeners } from './TimelineUtils';
import TimelineTooltip from './TimelineTooltip';
import { SurveyDataData } from '../../gl-analysis/GLAnalysisSurveyDateSlice';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface TimelineProps {
  cards: CardDateItemState[] | WellTestItemState[] | WellTestData[] | SurveyDataData[] | null;
  minDate: Date;
  maxDate: Date;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  onChange: (startDate: Date, endDate: Date) => void;
  dateRange?: DateRange;
  surveyCards?: SurveyDataData[];
}

interface SelectionRange {
  start: number | null;
  end: number | null;
  startDate: Date | null;
  endDate: Date | null;
}

interface HoverTooltip {
  start: number | null;
  startDate: Date | null;
}

const Timeline: React.FC<TimelineProps> = ({ cards, minDate, maxDate, onChange, dateRange, surveyCards }) => {
  const startRef = useRef<number>(0);
  const endRef = useRef<number>(0);

  const MIN_DATE = dateRange
    ? dateRange.startDate
    : cards && cards.length > 0
    ? new Date([...cards].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())[0].date)
    : null;
  const MAX_DATE = dateRange
    ? dateRange.endDate
    : cards && cards.length > 0
    ? new Date([...cards].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0].date)
    : null;

  const [startDate, setStartDate] = useState<Date>(minDate);
  const [endDate, setEndDate] = useState<Date>(maxDate);
  const [width, setWidth] = useState<number>(0);
  const [selectionRange, setSelectionRange] = useState<SelectionRange>({
    start: 0,
    end: 0,
    startDate: null,
    endDate: null,
  });
  const [hoverTooltip, setHoverTooltip] = useState<HoverTooltip>({ start: null, startDate: null });

  const timelineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!minDate || !maxDate) return;

    setStartDate(minDate);
    setEndDate(maxDate);

    setSelectionRange({ start: null, end: null, startDate: null, endDate: null });

    onChange(minDate, maxDate);
  }, [minDate, maxDate]);

  useEffect(() => {
    startRef.current = 0;
    endRef.current = width;
  }, [width]);

  useEffect(() => {
    const timelineElement = timelineRef.current;
    let resizeObserver: ResizeObserver;

    if (timelineElement) {
      resizeObserver = new ResizeObserver((entries) => {
        const { width } = entries[0].contentRect;

        if (width !== 0) {
          setWidth(width);
        }
      });

      resizeObserver.observe(timelineElement);
    }

    return () => {
      if (resizeObserver && timelineElement) {
        resizeObserver.unobserve(timelineElement);
      }
    };
  }, []);

  useDragEventListeners(timelineRef, setSelectionRange, width, startDate, endDate, onChange);

  const cardDateLines = useMemo(() => {
    const combinedCards =
      cards && surveyCards
        ? ([
            ...cards.map((x) => ({ ...x, cardTypeName: 'Well Test' })),
            ...surveyCards.map((x) => ({ ...x, cardTypeName: 'Survey' })),
          ] as WellTestData[] | SurveyDataData[])
        : cards;
    return cards && cards.length > 0 && combinedCards && startDate != null && endDate != null && width > 0
      ? renderCardDateLines(combinedCards, startDate, endDate, width)
      : null;
  }, [cards, startDate, endDate, width, surveyCards]);

  const TimelineTooltipMemo = React.memo(TimelineTooltip);

  const handleTooltipOnHover = (event) => {
    if (timelineRef.current) {
      const { left } = timelineRef.current.getBoundingClientRect();
      const mouseXRelativeToTimeline = event.clientX - left;

      const calculatedDateFromMousePosition = new Date(
        startDate.getTime() + (endDate.getTime() - startDate.getTime()) * (mouseXRelativeToTimeline / width),
      );

      setHoverTooltip((prevState) => ({
        ...prevState,
        start: mouseXRelativeToTimeline,
        startDate: calculatedDateFromMousePosition,
      }));
    }
  };

  return startDate && endDate && startDate >= minDate && endDate <= maxDate ? (
    <div className='timeline-outer-container'>
      <div
        className='timeline-tooltip-container'
        style={{
          left:
            selectionRange.start != null
              ? selectionRange.start < width - 100
                ? Math.max(selectionRange.start - 100, 0)
                : width - 199
              : 0,
          right: selectionRange.end != null ? Math.max(selectionRange.end - 100, 0) : width,
        }}
      >
        {hoverTooltip.start != null &&
          hoverTooltip.startDate != null &&
          (selectionRange.start == null || selectionRange.startDate == null) && (
            <div
              style={{
                position: 'relative',
                left:
                  hoverTooltip.start != null
                    ? hoverTooltip.start - 43 < width - 90
                      ? Math.max(hoverTooltip.start - 43, 0)
                      : width - 90
                    : 0,
                top: '-3px',
                zIndex: 2,
              }}
            >
              <TimelineTooltipMemo
                active={hoverTooltip.start != null}
                label={
                  hoverTooltip.startDate?.toLocaleString('en', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }) ?? ''
                }
              />
            </div>
          )}
        <TimelineTooltipMemo
          active={selectionRange.startDate != null}
          label={
            selectionRange.startDate?.toLocaleString('en', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }) ?? ''
          }
        />
        <TimelineTooltipMemo
          active={selectionRange.endDate != null}
          label={
            selectionRange.endDate?.toLocaleString('en', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }) ?? ''
          }
        />
      </div>
      <div className='timeline-container timeline-card-viewer-container-border'>
        {MIN_DATE != null &&
        MAX_DATE != null &&
        (startDate.getTime() !== MIN_DATE.getTime() || endDate.getTime() !== MAX_DATE.getTime()) ? (
          <div
            className='timeline-reset-button-container'
            onClick={() => MIN_DATE && MAX_DATE && onChange(MIN_DATE, MAX_DATE)}
          >
            <div className='timeline-reset-button-line' />
          </div>
        ) : null}
        <div
          id='timeline'
          ref={timelineRef}
          className='timeline-inner-container'
          onMouseMove={handleTooltipOnHover}
          onMouseLeave={() => setHoverTooltip({ start: null, startDate: null })}
        >
          <div className='timeline-date-line-container'>{cardDateLines}</div>
          {selectionRange.start != null && selectionRange.end != null && (
            <div
              id='date-range-box'
              className='timeline-date-range-box'
              style={{
                left: `${Math.min(selectionRange.start)}px`,
                right: `${Math.abs(selectionRange.end)}px`,
              }}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default Timeline;

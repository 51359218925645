import { createSlice } from '@reduxjs/toolkit';

interface State {
    industryApplicationId: number;
  }

const initialState: State = {
    industryApplicationId: 0,
  }

const AssetAnalysisSlice = createSlice({
  name: 'AssetAnalysis',
  initialState,
  reducers: {
    setIndustryApplicationId: (state, action) => {
      state.industryApplicationId = action.payload.industryApplicationId;
    },
  },
});

export const industryApplicationId = (state) => state.AssetAnalysis.industryApplicationId;
export const { setIndustryApplicationId } = AssetAnalysisSlice.actions;
export default AssetAnalysisSlice.reducer;
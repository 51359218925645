import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchAssetGroupsInitialState } from './AssetGroupsService';

interface State {
  loading: boolean;
  groups: Array<Group> | null | undefined;
  searchGroups: Array<Group> | null | undefined;
  error: string | undefined;
  selectedAssetId: string | undefined;
  selectedAssetGuid: string | undefined;
  selectedAsset: Asset | undefined;
  assets: Array<Asset> | null | undefined;
  selectedGroup: Group | undefined;
  groupHashMap: Record<number, Group>;
  socketId: string | undefined;
}
interface ActionPayload {
  groupId: number | undefined;
  assetId: string | undefined;
  searchString: string | undefined;
  assetIdGuid: string | undefined;
}
interface SocketActionPayload {
  socketId: string | undefined;
}
export interface Group {
  id: number;
  assetGroupName: string;
  childGroups: Array<Group>;
  assets: Array<Asset>;
  showAssets: boolean;
  class: string;
}

export interface Asset {
  assetId: string;
  assetName: string;
  industryApplicationId: number;
  wellOpType: number | null;
  enabled: boolean;
}

interface GroupResponse {
  groupName: string;
  childGroups: Array<GroupResponse>;
  assets: Array<AssetResponse>;
}

interface AssetResponse {
  assetId: string;
  assetName: string;
  industryApplicationId: number;
  wellOpType: number | null;
  enabled: boolean;
}

// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk('assetGroupsSlice/fetchInitialState', async () => {
  const data = await fetchInitialState();
  const groupAndAssetData = data.values;
  const groups = mapGroupResponseToGroup(groupAndAssetData);
  const groupHashMap = createGroupHashMap(groups);
  const assets = Object.values(groupHashMap).flatMap((group) => group.assets);

  return { groups, groupHashMap, assets };
});

const createGroupHashMap = (groups: Group[]): Record<number, Group> => {
  const hashMap: Record<number, Group> = {};
  const addToHashMap = (group: Group) => {
    hashMap[group.id] = group;
    group.childGroups.forEach(addToHashMap);
  };
  groups.forEach(addToHashMap);
  return hashMap;
};

let groupId = 1;

const mapGroupResponseToGroup = (groups: GroupResponse[]): Group[] =>
  groups.map((group: GroupResponse) => ({
    id: groupId++,
    assetGroupName: group.groupName,
    childGroups: group.childGroups?.length ? mapGroupResponseToGroup(group.childGroups) : [],
    assets:
      group.assets?.map(
        (asset: AssetResponse): Asset => ({
          assetId: asset.assetId,
          assetName: asset.assetName,
          industryApplicationId: asset.industryApplicationId,
          wellOpType: asset.wellOpType,
          enabled: asset.enabled,
        }),
      ) ?? [],
    showAssets: false,
    class: 'navigation-content-frame-item',
  }));

const initialState: State = {
  loading: false,
  groups: null,
  searchGroups: null,
  error: undefined,
  selectedAssetId: undefined,
  selectedAsset: undefined,
  assets: null,
  selectedGroup: undefined,
  groupHashMap: {},
  socketId: undefined,
  selectedAssetGuid: undefined,
};

const updateGroupTree = (groups, groupId, newGroup, searchString) => {
  if (!groups) return null;
  return groups.map((group) => {
    if (group.id === groupId) {
      return {
        ...newGroup,
        assets: newGroup.assets.filter((asset) => asset.assetName.toLowerCase().startsWith(searchString)),
      };
    } else {
      return {
        ...group,
        childGroups: updateGroupTree(group.childGroups, groupId, newGroup, searchString),
      };
    }
  });
};

const resetShowAssets = (groups) => {
  if (!groups) return null;
  return groups.map((group: Group) => {
    return {
      ...group,
      showAssets: false,
      childGroups: resetShowAssets(group.childGroups),
      class: 'navigation-content-frame-item',
    };
  });
};

const setGroupHashMapDisplayState = (groupHashMap: Record<number, Group>, active: boolean): Record<number, Group> => {
  const resetGroupDisplayState = (group: Group): Group => ({
    ...group,
    showAssets: active,
    class: active ? 'navigation-content-frame-item item-selected' : 'navigation-content-frame-item',
    childGroups: group.childGroups.map(resetGroupDisplayState),
  });

  const newHashMap = { ...groupHashMap };
  Object.keys(newHashMap).forEach((key) => {
    newHashMap[key] = resetGroupDisplayState(newHashMap[key]);
  });
  return newHashMap;
};

export const assetGroupsSlice = createSlice({
  name: 'assetGroups',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    updateAssetsDisplayState: (state, action: PayloadAction<ActionPayload>) => {
      const id = action.payload.groupId;
      if (!id) {
        return;
      }

      const group = state.groupHashMap[id];

      if (group) {
        const showAssets = !group.showAssets;
        state.groupHashMap[id] = {
          ...group,
          showAssets,
          class: 'navigation-content-frame-item',
        };

        const searchString = action.payload.searchString?.toLowerCase() || '';
        state.groups = updateGroupTree(state.groups, id, state.groupHashMap[id], searchString);
        state.searchGroups = updateGroupTree(state.searchGroups, id, state.groupHashMap[id], searchString);
      }
    },
    updateSelectedAsset: (state, action: PayloadAction<ActionPayload>) => {
      state.selectedAssetId = action.payload.assetId;
      state.selectedAsset = state.assets?.find((asset) => asset.assetId === action.payload.assetId);
      state.selectedAssetGuid = action.payload.assetIdGuid;

      if (action.payload.groupId) {
        if (state.selectedGroup?.id != action.payload.groupId) {
          state.selectedGroup = state.groupHashMap[action.payload.groupId];
        }
      } else {
        state.selectedGroup = undefined;
      }
    },
    updateSocketId: (state, action: PayloadAction<SocketActionPayload>) => {
      state.socketId = action.payload.socketId;
    },
    updateSearchGroups: (state, action: PayloadAction<ActionPayload>) => {
      const searchString = action.payload.searchString?.toLowerCase();
      state.searchGroups = resetShowAssets(state.groups);
      if (searchString) {
        state.groupHashMap = setGroupHashMapDisplayState(state.groupHashMap, true);

        const allGroups = Object.values(state.groupHashMap);
        state.searchGroups = allGroups
          .filter((group: Group) =>
            group.assets.some((asset) => asset.assetName.toLowerCase().startsWith(searchString)),
          )
          .map((group: Group) => ({
            ...group,
            showAssets: true,
            assets: group.assets.filter((asset) => asset.assetName.toLowerCase().startsWith(searchString)),
          }));
      } else {
        state.groupHashMap = setGroupHashMapDisplayState(state.groupHashMap, false);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
        if (!state.groups || !state.groups.length) {
          state.loading = false;
          state.groups = action.payload.groups;
          state.groupHashMap = action.payload.groupHashMap;
          state.searchGroups = action.payload.groups;
          state.assets = action.payload.assets;
        }
      })
      .addCase(fetchInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const fetchInitialState = async () => {
  try {
    const response = await fetchAssetGroupsInitialState();
    return response;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    throw error;
  }
};

// Export the actions from the itemsSlice
export const { updateAssetsDisplayState, updateSelectedAsset, updateSearchGroups, setInitialState, updateSocketId } =
  assetGroupsSlice.actions;

export const selectAssetGroups = (state: RootState) => state.assetGroups;

export default assetGroupsSlice.reducer;

import React, { useEffect, useState } from 'react';
import './AssetAnalysis.css';
import '../rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis.css';
import RodLiftAnalysis from '../rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis';
import ESPAnalysis from '../esp-analysis/ESPAnalysis/ESPAnalysis';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks';
import { GLAnalysis } from '../gl-analysis/GLAnalysis/GLAnalysis';
import TrendGroupLabel from './TrendGroupLabel/TrendGroupLabel';
import {
  updateDateFlag,
  updategroupChartCoordinates,
  updateOverlayCoordinates,
} from './../data-history/TrendLibrary/TrendCoordinateSlice.tsx';
import { useParams } from 'react-router-dom';
import {
  updateGroupCheckedBoxes,
  updateCheckedItems,
  updateGroupedChartSelection,
} from './../../features/data-history/TrendLibrary/TrendLibrarySlice';
import { groupedChartData } from './../../features/data-history/TrendLibrary/ChartTypes';
import { fetchGetDefaultTrendsDataAsync } from './../../features/data-history/TrendLibrary/DefaultTrendsSlice.tsx';
import { fetchInitialTrendCustomViewsAsync } from './../../features/asset-analysis/TrendGroupLabel/TrendGroupLabelSlice.tsx';

export interface AnalysisTimelineProps {
  startDate: Date | null;
  endDate: Date | null;
  minDate: Date;
  maxDate: Date;
  isDateLinked: boolean;
  showDateLinkIcon: boolean;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setMinDate: (date: Date) => void;
  setMaxDate: (date: Date) => void;
  onClickLinkIcon: (id: number) => void;
  datepickerOnChange: (
    value: { startDate: Date | string | null; endDate: Date | string | null } | null,
    minDate: Date | null,
    maxDate: Date | null,
    setMinDate: (date: Date) => void,
    setMaxDate: (date: Date) => void,
  ) => void;
  setShowDateLinkIcon: (value: boolean) => void;
}

export interface TrendGroupTimelineProps {
  startDate: Date | null;
  endDate: Date | null;
  minDate: Date;
  maxDate: Date;
  isDateLinked: boolean;
  showDateLinkIcon: boolean;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setMaxDate: (date: Date) => void;
  setMinDate: (date: Date) => void;

  onClickLinkIcon: (id: number) => void;
  datepickerOnChange: (
    value: { startDate: Date | string | null; endDate: Date | string | null } | null,
    minDate: Date | null,
    maxDate: Date | null,
    setMinDate: (date: Date) => void,
    setMaxDate: (date: Date) => void,
    useOffset?: boolean,
  ) => void;
}

interface DatepickerOnChangeParams {
  startDate: Date | string | null;
  endDate: Date | string | null;
}

interface UrlParams {
  urlAssetId: string;
}

const ONE_DAY = 1000 * 60 * 60 * 24;

const AssetAnalysis = () => {
  const industryApplicationId = useAppSelector((state) => state.AssetAnalysis.industryApplicationId);
  const selectedAssetGuid = useAppSelector((state) => state.assetGroups.selectedAssetGuid);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date>(new Date(new Date().setTime(new Date().getTime() - ONE_DAY)));
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [isDateLinked, setIsDateLinked] = useState<boolean>(false);
  const [showDateLinkIcon, setShowDateLinkIcon] = useState<boolean>(false);

  const [trendGroupStartDate, setTrendGroupStartDate] = useState<Date | null>(null);
  const [trendGroupEndDate, setTrendGroupEndDate] = useState<Date | null>(null);
  const [trendGroupMinDate, setTrendGroupMinDate] = useState<Date>(
    new Date(new Date().setTime(new Date().getTime() - ONE_DAY)),
  );
  const [trendGroupMaxDate, setTrendGroupMaxDate] = useState<Date>(new Date());
  const { assetId } = useParams<keyof UrlParams>() as UrlParams;

  const [priorityTimelinePropsId, setPriorityTimelinePropsId] = useState<number>(-1);
  const trendCustomViews = useAppSelector((state) => state.trendGroupLabel.customViews);
  const trendCustomLoader = useAppSelector((state) => state.trendGroupLabel.loading);

  const customViewDataSlice = useAppSelector((state) => state.customViewDataSlice);
  const dispatch = useAppDispatch();
  const trendLibrary = useAppSelector((state) => state.trendLibrary);
  const hasDateBeenUpdated = useAppSelector((state) => state.trendItem.isDatedUpdated);
  const selectedView = useAppSelector((state) => state.trendGroupLabel.selectedView);
  const trendLibraryHasBeenClicked = useAppSelector((state) => state.trendItem.isTrendLibraryClicked);

  const setDefaultValues = () => {
    setStartDate(null);
    setEndDate(null);
    setMinDate(new Date(new Date().setTime(new Date().getTime() - ONE_DAY)));
    setMaxDate(new Date());
    setIsDateLinked(false);
    if (trendGroupStartDate && trendGroupEndDate) {
      setTrendGroupStartDate(trendGroupStartDate);
      setTrendGroupEndDate(trendGroupEndDate);
      setTrendGroupMinDate(new Date(new Date().setTime(new Date(trendGroupStartDate).getTime())));
      setTrendGroupMaxDate(new Date(new Date().setTime(new Date(trendGroupEndDate).getTime())));
    } else {
      setTrendGroupStartDate(null);
      setTrendGroupEndDate(null);
      setTrendGroupMinDate(new Date(new Date().setTime(new Date().getTime() - ONE_DAY)));
      setTrendGroupMaxDate(new Date());
    }
    setPriorityTimelinePropsId(-1);
    setShowDateLinkIcon(false);
  };

  useEffect(() => {
    if (showDateLinkIcon === false) {
      setPriorityTimelinePropsId(-1);
      setIsDateLinked(false);
    }
  }, [showDateLinkIcon]);

  useEffect(() => {
    setDefaultValues();
  }, [selectedAssetGuid]);

  const onClickLinkIcon = (id: number) => {
    if (isDateLinked) {
      setPriorityTimelinePropsId(-1);
      setIsDateLinked(false);
    } else {
      setPriorityTimelinePropsId(id);
      setIsDateLinked(true);
    }
  };

  const datepickerOnChange = (
    value: DatepickerOnChangeParams | null,
    minDate: Date | null,
    maxDate: Date | null,
    setMinDate: (date: Date) => void,
    setMaxDate: (date: Date) => void,
    useOffset = true,
  ) => {
    if (!value || !minDate || !maxDate) return;

    if (useOffset === false) {
      if (value.startDate) {
        const startDate = new Date(value.startDate);
        setMinDate(startDate);
      }

      if (value.endDate) {
        const endDate = new Date(value.endDate);
        setMaxDate(endDate);
      }
    } else {
      if (value.startDate) {
        const startDate = new Date(value.startDate);
        startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000);

        setMinDate(startDate);
      }
      if (value.endDate) {
        const endDate = new Date(value.endDate);
        endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000);
        endDate.setHours(23, 59, 59, 999);
        setMaxDate(endDate);
      }
    }

    dispatch(updateDateFlag(true));
  };

  const analysisTimelineProps = {
    startDate,
    endDate,
    minDate,
    maxDate,
    isDateLinked,
    showDateLinkIcon,
    setStartDate,
    setEndDate,
    setMinDate,
    setMaxDate,
    onClickLinkIcon,
    datepickerOnChange,
    setShowDateLinkIcon,
  };

  const trendGroupTimelineProps = {
    startDate: trendGroupStartDate,
    endDate: trendGroupEndDate,
    minDate: trendGroupMinDate,
    maxDate: trendGroupMaxDate,
    isDateLinked,
    showDateLinkIcon: true,
    setStartDate: setTrendGroupStartDate,
    setEndDate: setTrendGroupEndDate,
    setMinDate: setTrendGroupMinDate,
    setMaxDate: setTrendGroupMaxDate,
    onClickLinkIcon,
    datepickerOnChange,
    setShowDateLinkIcon,
  };

  const activeKey = useAppSelector((state) => state.trendGroupLabel.activeChartKey);

  useEffect(() => {
    dispatch(fetchInitialTrendCustomViewsAsync());
  }, []);
  const getselectedView = () => {
    if (selectedView && selectedView.viewName) {
      return selectedView;
    } else {
      if (trendCustomViews && trendCustomViews.values && trendCustomViews.values.length) {
        return trendCustomViews?.values?.find((view) => view.isSelected) ?? trendCustomViews.values[0];
      }
    }
  };
  useEffect(() => {
    let selectedView: any = null;
    if (Array.isArray(trendCustomViews.values) && activeKey !== -1 && !trendCustomLoader) {
      selectedView = getselectedView();
      if (selectedView || !hasDateBeenUpdated) {
        if (activeKey === 1) {
          dispatch(
            fetchGetDefaultTrendsDataAsync({
              assetId,
              viewId: selectedView?.viewId,
              startDate: startDate?.toISOString(),
              endDate: endDate?.toISOString(),
            }),
          );
        } else {
          dispatch(
            fetchGetDefaultTrendsDataAsync({
              assetId,
              viewId: selectedView?.viewId,
              startDate: startDate?.toISOString(),
              endDate: endDate?.toISOString(),
              isOverlay: 1,
            }),
          );
        }
      }
    }
    if (activeKey === 0) {
      if (customViewDataSlice && customViewDataSlice.overlayDefaultCustomViewTrends) {
        populateDefaultTrends(customViewDataSlice.overlayDefaultCustomViewTrends, true);
      }
    } else {
      if (customViewDataSlice && customViewDataSlice.groupDefaultCustomViewTrends) {
        populateDefaultTrends(customViewDataSlice.groupDefaultCustomViewTrends, false);
      }
    }
  }, [trendCustomViews]);

  useEffect(() => {
    populateDefaultTrends(customViewDataSlice.overlayDefaultCustomViewTrends, true);
  }, [customViewDataSlice.overlayDefaultCustomViewTrends]);

  useEffect(() => {
    populateDefaultTrends(customViewDataSlice.groupDefaultCustomViewTrends, false);
  }, [customViewDataSlice.groupDefaultCustomViewTrends]);

  const populateDefaultTrends = (defaultTrendsData, isOverlay: boolean) => {
    if (trendLibrary && trendLibrary.trends) {
      //initialized chart data based on endpoint

      if (isOverlay) {
        if (!defaultTrendsData) return;

        if (!trendLibrary.ischeckBoxUpdated) {
          dispatch(updateCheckedItems(defaultTrendsData.chart1 ?? []));
          dispatch(
            updateOverlayCoordinates({
              value: customViewDataSlice.overlayDefaultCustomViewTrends,
            }),
          );
        }
      } else {
        if (!defaultTrendsData) return;

        const chart1Data = defaultTrendsData.chart1;
        const chart2Data = defaultTrendsData.chart2;
        const chart3Data = defaultTrendsData.chart3;
        const chart4Data = defaultTrendsData.chart4;

        if (!trendLibraryHasBeenClicked) {
          dispatch(
            updategroupChartCoordinates({
              value: customViewDataSlice.groupDefaultCustomViewTrends,
              type: 'group',
            }),
          );
          //update checkbox states with selections
          dispatch(
            updateGroupCheckedBoxes({
              chart1: chart1Data ?? [],
              chart2: chart2Data ?? [],
              chart3: chart3Data ?? [],
              chart4: chart4Data ?? [],
            }),
          );
          const trendsGroupSelectionClone = groupedChartData.map((item, index) => {
            const selectedTrendsArray =
              index === 0
                ? chart1Data
                : index === 1
                ? chart2Data
                : index === 2
                ? chart3Data
                : index === 3
                ? chart4Data
                : [];

            return { ...item, selectedTrendsArray };
          });
          dispatch(updateGroupedChartSelection(trendsGroupSelectionClone));
        }
      }
    }
  };

  return (
    <div className='page-layout analysis'>
      <div className='flex flex-row'>
        {industryApplicationId === 3 || industryApplicationId === 7 || industryApplicationId === 4 ? (
          <>
            <div className='w-1/2 col'>
              {industryApplicationId == 3 ? (
                <RodLiftAnalysis
                  timelineProps={priorityTimelinePropsId === 1 ? trendGroupTimelineProps : analysisTimelineProps}
                />
              ) : industryApplicationId == 7 ? (
                <GLAnalysis
                  timelineProps={priorityTimelinePropsId === 1 ? trendGroupTimelineProps : analysisTimelineProps}
                />
              ) : industryApplicationId == 4 ? (
                <ESPAnalysis
                  timelineProps={priorityTimelinePropsId === 1 ? trendGroupTimelineProps : analysisTimelineProps}
                />
              ) : (
                <div></div>
              )}
            </div>
            <div className='w-1/2 col right'>
              <TrendGroupLabel
                timelineProps={priorityTimelinePropsId === 0 ? analysisTimelineProps : trendGroupTimelineProps}
              />
            </div>
          </>
        ) : (
          <div className='w-full col'>
            <TrendGroupLabel
              timelineProps={priorityTimelinePropsId === 0 ? analysisTimelineProps : trendGroupTimelineProps}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetAnalysis;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Notification } from '../../models/Notification';
import { fetchNotificationsInitialState } from './NotificationService';

interface State {
  loading: boolean;
  error: string | undefined;
  notifications: Array<Notification>;
}

interface FetchNotificationsArgs {
  type: string;
  id: string;
  notificationTypeId: string;
}

export const fetchNotificationsAsync = createAsyncThunk(
  'notificationsSlice/fetchNotifications',
  async (args: FetchNotificationsArgs) => {
    const { type, id, notificationTypeId } = args;
    const data = await fetchNotifications(type, id, notificationTypeId);
    if (data && data.values) {
      return data.values;
    }
    return [];
  },
);

const initialState: State = {
  loading: false,
  error: undefined,
  notifications: [],
};

export interface WellControlNotification {
  NodeId: string;
  EventType: number;
  EventTypeName: string;
  Note: string;
  Source: string;
  Date: string;
}

export const notificationsSlice = createSlice({
  name: 'notificationsList',
  initialState,
  reducers: {
    addNotificationsFromWellControlTransaction: (state, action) => {
      const notifications = state.notifications;
      const notificationsToAdd: WellControlNotification[] = action.payload.notifications;
      const mappedNotificationsToAdd = notificationsToAdd.map((notification) => {
        return {
          date: notification.Date,
          eventId: 0,
          eventTypeId: notification.EventType,
          nodeId: notification.NodeId,
          dtatus: '',
          note: notification.Note,
          userId: '',
          transactionId: 0,
          eventTypeName: notification.EventTypeName,
        } as Notification;
      });
      notifications.unshift(...mappedNotificationsToAdd);
      state.notifications = notifications;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(fetchNotificationsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const fetchNotifications = async (type: string, id: string, notificationTypeId: string) => {
  try {
    let notificationURL = `Notifications?type=${type}&id=${id}`;
    if (notificationTypeId) {
      notificationURL = `$Notifications?type=${type}&id=${id}&notificationTypeId=${notificationTypeId}`;
    }
    const response = await fetchNotificationsInitialState(notificationURL);

    return response;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return {};
  }
};
export const notifications = (state: RootState) => state.notificationsList;

export const { addNotificationsFromWellControlTransaction } = notificationsSlice.actions;
export default notificationsSlice.reducer;

import React from 'react';
import './ServerError.scss';
import PrimaryX from '../../images/primary_X.svg';
import ServerErrorImg from '../../images/servererror.svg';

const ServerError = () => {
  return (
    <div className='servererror-background'>
      <div className='servererror-message'>
        <img alt='PrimaryX' src={PrimaryX} className='servererror-inlineblock mr-5' />
        <div className='servererror-inlineblock font-bold'>ChampionX</div>
        <div className='font-bold mt-10'>Our server is feeling a little down</div>
        <br />
        <div className='text-xs'>Please try again in a few moments. we'll be back up in no time.</div>
        <div className='text-xs servererror-xspocmargin'>@ XSPOC 2023</div>
      </div>
      <div className='servererror-logo'>
        <img alt='ServerErrorImg' src={ServerErrorImg} className='w-100 servererror-imgmargintop' />
      </div>
    </div>
  );
};

export default ServerError;

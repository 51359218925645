import { createSlice } from '@reduxjs/toolkit';

interface ViewOptionsState {
  enabledStates: boolean[];
}

const initialState: ViewOptionsState = {
  enabledStates: [],
};

const viewOptionsSlice = createSlice({
  name: 'viewOptions',
  initialState,
  reducers: {
    setEnabledStates: (state, action) => {
      state.enabledStates = action.payload;
    },
  },
});

export const selectEnabledStates = (state) => state.viewOptions.enabledStates;
export const { setEnabledStates } = viewOptionsSlice.actions;
export default viewOptionsSlice.reducer;
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import api from '../../services/api';

interface State {
  loading: boolean;
  groups: Array<Group | undefined> | null | undefined;
  searchGroups: Array<Group> | null | undefined;
  error: string | undefined;
  selectedAssetId: string | undefined;
  selectedAsset: Asset | undefined;
  assets: Array<Asset> | null | undefined;
  selectedGroup: Group | undefined;
  groupHashMap: Record<number, Group>;
}
interface ActionPayload {
  groupId: number | undefined;
  childGroupId: number | undefined;
  assetId: string | undefined;
  searchString: string | undefined;
  groupName: string | undefined;
}
export interface Group {
  id: number;
  assetGroupName: string;
  childGroups: Array<Group>;
  assets: Array<Asset>;
  showAssets: boolean;
  isSelected: boolean;
  class: string;
}

export interface Asset {
  assetId: string;
  assetName: string;
  industryApplicationId: number;
  isSelected: boolean;
}

interface GroupResponse {
  groupName: string;
  childGroups: Array<GroupResponse>;
  assets: Array<AssetResponse>;
}

interface AssetResponse {
  assetId: string;
  assetName: string;
  industryApplicationId: number;
}

// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk('signInDefaultViewSlice/fetchInitialState', async () => {
  const data = await fetchInitialState();
  const groupAndAssetData = data.values;
  const groups = mapGroupResponseToGroup(groupAndAssetData);
  const groupHashMap = createGroupHashMap(groups);
  const assets = Object.values(groupHashMap).flatMap((group) => group.assets);

  return { groups, groupHashMap, assets };
});

const createGroupHashMap = (groups: Group[]): Record<number, Group> => {
  const hashMap: Record<number, Group> = {};
  const addToHashMap = (group: Group) => {
    hashMap[group.id] = group;
    group.childGroups.forEach(addToHashMap);
  };
  groups.forEach(addToHashMap);
  return hashMap;
};

let groupId = 1;

const mapGroupResponseToGroup = (groups: GroupResponse[]): Group[] =>
  groups.map((group: GroupResponse) => ({
    id: groupId++,
    assetGroupName: group.groupName,
    childGroups: group.childGroups?.length ? mapGroupResponseToGroup(group.childGroups) : [],
    assets:
      group.assets?.map(
        (asset: AssetResponse): Asset => ({
          assetId: asset.assetId,
          assetName: asset.assetName,
          industryApplicationId: asset.industryApplicationId,
          isSelected: false,
        }),
      ) ?? [],
    showAssets: false,
    isSelected: false,
    class: 'navigation-content-frame-item',
  }));

const initialState: State = {
  loading: false,
  groups: null,
  searchGroups: null,
  error: undefined,
  selectedAssetId: undefined,
  selectedAsset: undefined,
  assets: null,
  selectedGroup: undefined,
  groupHashMap: {},
};

export const signInDefaultViewSlice = createSlice({
  name: 'signInDefaultView',
  initialState,
  reducers: {
    updateSelectedAsset: (state, action: PayloadAction<ActionPayload>) => {
      state.selectedAssetId = action.payload.assetId;
      state.selectedAsset = state.assets?.find((asset) => asset.assetId === action.payload.assetId);

      if (action.payload.groupId) {
        state.selectedGroup = state.groupHashMap[action.payload.groupId];
      } else {
        state.selectedGroup = undefined;
      }
    },
    updateGroupAssetsDisplay: (state, action: PayloadAction<ActionPayload>) => {
      const { groupId, assetId } = action.payload;

      state.groups = updateGroupASsetState(state.groups, state, assetId, groupId);
    },
    updateAssetDisplay: (state, action: PayloadAction<ActionPayload>) => {
      const selectedGroupId = action.payload.groupId;
      const updatedGroupsState = updateGroupState(state.groups, selectedGroupId, state);
      state.groups = updatedGroupsState;
    },
    updateChildGroupAssetDisplay: (state, action: PayloadAction<ActionPayload>) => {
      const selectedGroupId = action.payload.groupId;
      const selectedChildGroupId = action.payload.childGroupId;
      const updatedGroupsState = state.groups?.map((group) => {
        if (group && group?.id == selectedGroupId) {
          const updatedChildGroups = group?.childGroups.map((childGroup) => {
            if (childGroup && childGroup.id == selectedChildGroupId) {
              const showAssets = childGroup.showAssets;
              childGroup.showAssets = !showAssets;
            }

            if (childGroup?.isSelected || childGroup?.assets.some((asset) => asset.isSelected)) {
              state.selectedGroup = childGroup;
            }

            const selectedAsset = childGroup?.assets.find((asset) => asset.isSelected);

            if (selectedAsset) {
              state.selectedAsset = selectedAsset;
            }

            return childGroup;
          });

          group.childGroups = updatedChildGroups;
        }

        if (group?.isSelected || group?.assets.some((asset) => asset.isSelected)) {
          state.selectedGroup = group;
        }

        const selectedAsset = group?.assets.find((asset) => asset.isSelected);

        if (selectedAsset) {
          state.selectedAsset = selectedAsset;
        }

        return group;
      });
      state.groups = updatedGroupsState;
    },
    resetAllSelected: (state) => {
      if (!state.groups) {
        return;
      }

      state.groups.map((group) => {
        if (!group) {
          return;
        }

        group.isSelected = false;
        group.assets?.map((asset) => {
          asset.isSelected = false;
        });
        resetAllChildGroupsSelected(group.childGroups);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload.groups;
        state.groupHashMap = action.payload.groupHashMap;
        state.searchGroups = action.payload.groups;
        state.assets = action.payload.assets;
      })
      .addCase(fetchInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const updateGroupASsetState = (groups, state, assetId, groupId) => {
  return groups?.map((group) => {
    if (group.childGroups) {
      updateGroupASsetState(group.childGroups, state, assetId, groupId);
    }
    if (group?.id === groupId) {
      updateGroupAssets(group, assetId);

      if (group?.isSelected || group?.assets.some((asset) => asset.isSelected)) {
        state.selectedGroup = group;
      }

      const selectedAsset = group?.assets.find((asset) => asset.isSelected);

      if (selectedAsset) {
        state.selectedAsset = selectedAsset;
      }
    }
    return group;
  });
};
const updateGroupState = (groups, selectedGroupId, state) => {
  return groups.map((group) => {
    if (group && group.id == selectedGroupId) {
      const showAssets = group.showAssets;
      group.showAssets = !showAssets;
    }

    if (group.childGroups) {
      updateGroupState(group.childGroups, selectedGroupId, state);
    }

    if (group?.isSelected || group?.assets.some((asset) => asset.isSelected)) {
      state.selectedGroup = group;
    }

    const selectedAsset = group?.assets.find((asset) => asset.isSelected);

    if (selectedAsset) {
      state.selectedAsset = selectedAsset;
    }

    return group;
  });
};
const resetAllChildGroupsSelected = (childGroups: Array<Group>) => {
  if (!childGroups) {
    return;
  }

  childGroups.map((childGroup) => {
    if (!childGroup) {
      return;
    }

    childGroup.isSelected = false;
    childGroup.assets?.map((asset) => {
      asset.isSelected = false;
    });

    resetAllChildGroupsSelected(childGroup.childGroups);
  });
};

const updateGroupAssets = (group: Group | undefined, assetId?: string) => {
  if (group && assetId) {
    group.isSelected = false;
    group.assets = group?.assets?.map((asset) => {
      if (asset?.assetId === assetId) {
        asset.isSelected = !asset.isSelected;
      }
      return asset;
    });
  } else {
    const isGroupSelected = !group?.isSelected;
    group.isSelected = isGroupSelected;
    group.assets = group?.assets?.map((asset) => {
      asset.isSelected = false;
      return asset;
    });
    group.childGroups = group?.childGroups?.map((childGroup) => {
      childGroup.isSelected = false;
      childGroup.assets = childGroup.assets.map((childGroupAsset) => {
        childGroupAsset.isSelected = false;
        return childGroupAsset;
      });
      return childGroup;
    });
  }
};

export const fetchInitialState = async () => {
  try {
    const params = {
      groupBy: 'AssetGroupName',
    };
    return await api
      .get('Assets', {
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    throw error;
  }
};

// Export the actions from the itemsSlice
export const {
  updateSelectedAsset,
  updateGroupAssetsDisplay,
  updateAssetDisplay,
  updateChildGroupAssetDisplay,
  resetAllSelected,
} = signInDefaultViewSlice.actions;

export const selectAssetGroups = (state: RootState) => state.assetGroups;

export default signInDefaultViewSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks';
import { setUIButtons } from '../navigation/UIButtonToolbarSlice';
import UISettings from '../common/UIButtons/UISettings';
import UIDownloadButton from '../common/UIButtons/UIDownloadButton';
import Dropdown from '../common/Dropdown/Dropdown';
import { fetchInitialStateAsync } from './TableViewSlice';
import { fetchInitialGroupStatusStateAsync, setSelectedView } from './GroupStatusSlice';
import AssetDetailsTable from './AssetDetails';
import './GroupStatus.scss';
import Tooltip from '../common/tooltip/ToolTip';
import ControlActions from '../common/ControlActions/ControlActions';
import { fetchWellControlActions, fetchEnabledStatus } from '../asset/AssetDataSlice';
import Alarms from './GroupStatusWidgets/GroupStatusAlarms';
import GroupStatusWidgets from './GroupStatusWidgets/GroupStatusWidgets';
import { downloadCSV, generateCSVContent } from '../common/CSVUtils/csvUtils';
import {
  fetchGroupAlarmsWidgetDataAsync,
  fetchGroupClassificationWidgetDataAsync,
  fetchGroupDowntimeByRunStatusAsync,
  fetchGroupDowntimeByWellsAsync,
} from './GroupStatusWidgets/GroupStatusWidgetSlice';
import { useParams } from 'react-router-dom';
import FullScreenIcon from '../common/FullScreenIcon/FullScreenIcon';
import NoAssetsImage from './../../images/no-assets.svg';
import UIButtonToolbar from './../navigation/components/UIButtonToolbar';
import { updateSelectedAsset } from '../navigation/AssetGroupsSlice';

import Loader from '../common/Loader/Loader';

interface UrlParams {
  groupName: string;
}

const GroupStatus = () => {
  const uiButtons = [
    {
      name: 'List',
      active: true,
      id: 0,
    },
    {
      name: 'Map',
      active: false,
      id: 1,
    },
    {
      name: 'Alarms',
      active: false,
      id: 2,
    },
  ];
  const [assetId, setSelectedAssetId] = useState('');
  const [clickedRowIndex, setClickedRowIndex] = useState(null);

  //const [selectedView, setSelectedView] = useState();

  const wellControlActionTypes = useAppSelector((state) => state.assetData.wellControlActions);
  const isWellEnabled = useAppSelector((state) => state.assetData.wellEnabledStatus) ?? false;

  const { groupName } = useParams<keyof UrlParams>() as UrlParams;

  const tableViews = useAppSelector((state) => state.tableView.tableViews);
  const selectedView = useAppSelector((state) => state.groupStatus.selectedView);
  const groupStatus = useAppSelector((state) => state.groupStatus.groupStatus);
  const selectedGroup = useAppSelector((State) => State.assetGroups.selectedGroup);
  const assets = useAppSelector((state) => state.assetGroups);
  const wellGroupName = groupName ?? selectedGroup?.assetGroupName;
  const groupWidgetData = useAppSelector((state) => state.groupStatusWidget);
  const groupStatusLoading = useAppSelector((state) => state.groupStatus.groupStatusLoading);
  const [prevGroupName, setPrevGroupName] = useState('');
  const [prevSelectedView, setPrevSelectedView] = useState();
  const groupStatusAlarmsLoading = useAppSelector((state) => state.groupStatusWidget.alarmsLoading);
  const groupStatusClassificatonsLoading = useAppSelector((state) => state.groupStatusWidget.classificationsLoading);
  const groupStatusRunStatusLoading = useAppSelector((state) => state.groupStatusWidget.downtimeByRunStatusLoading);
  const groupStatusWellsLoading = useAppSelector((state) => state.groupStatusWidget.downtimeByWellsLoading);
  const groupStatusAlarmsError = useAppSelector((state) => state.groupStatusWidget.alarmsError);
  const groupStatusClassificationsError = useAppSelector((state) => state.groupStatusWidget.classificationsError);
  const groupStatusRunStatusError = useAppSelector((state) => state.groupStatusWidget.downtimeByWellsError);
  const groupStatusWellsError = useAppSelector((state) => state.groupStatusWidget.downtimeByRunStatusError);
  const canConfigWell = useAppSelector((state) => state.assetData.canConfigWell) ?? false;
  const { classificationValues, alarmsValues } = groupWidgetData;

  const classificationChartData =
    classificationValues && classificationValues.length > 0 ? classificationValues[0]?.classificationValues : [];
  const classificationAssetCount =
    classificationValues && classificationValues.length > 0 ? classificationValues[0]?.assetCount : [];
  const alarmsValue = alarmsValues?.values?.[0]?.count ?? 0;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setUIButtons(uiButtons));
    dispatch(fetchInitialStateAsync());
  }, []);

  useEffect(() => {
    if (wellGroupName) {
      dispatch(fetchGroupClassificationWidgetDataAsync(wellGroupName));
      dispatch(fetchGroupAlarmsWidgetDataAsync(wellGroupName));
      dispatch(fetchGroupDowntimeByWellsAsync(wellGroupName));
      dispatch(fetchGroupDowntimeByRunStatusAsync(wellGroupName));
    }
  }, [wellGroupName]);

  useEffect(() => {
    if (tableViews && tableViews.values.length > 0 && groupName) {
      let viewId = selectedView?.id;
      const currentView = selectedView;
      if (!viewId) {
        const previewView = tableViews.values.filter((view) => view.isSelectedView)[0] ?? tableViews?.values[0];
        viewId = previewView.id;

        dispatch(setSelectedView({ selectedView: previewView }));
      }

      if (viewId) {
        const groupStatusData = {
          viewId: viewId,
          group: groupName,
        };

        if (
          (currentView && prevSelectedView && prevSelectedView?.name !== currentView?.name) ||
          groupName !== prevGroupName
        ) {
          setPrevGroupName(groupName);
          dispatch(fetchInitialGroupStatusStateAsync(groupStatusData));
        }
      }
    }
  }, [tableViews, selectedView, selectedGroup]);

  function onSelectedViewChange(localSelectedView) {
    const selectedViewDetails = tableViews?.values.filter((view) => view.name == localSelectedView.name);
    setPrevSelectedView(selectedView);
    dispatch(setSelectedView({ selectedView: selectedViewDetails[0] }));
  }

  const handleAssetDetailsRowClick = React.useCallback(
    (asset, index) => {
      const assetName = asset?.values?.Well;

      const assetId = asset.values.Assetguid;
      setSelectedAssetId(assetId);
      dispatch(fetchWellControlActions(assetId));
      dispatch(fetchEnabledStatus(assetId));

      dispatch(
        updateSelectedAsset({
          groupId: selectedGroup?.id,
          assetId: assetName,
          searchString: undefined,
          assetIdGuid: assetId,
        }),
      );
      console.log(index);
      setClickedRowIndex(index);
    },
    [selectedGroup],
  );

  const handleDownloadClick = () => {
    const columns = groupStatus?.values?.columns || [];
    const rows = groupStatus?.values?.rows || [];

    const csvContent = generateCSVContent(columns, rows);

    downloadCSV(csvContent, 'group_status.csv');
  };

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  const isAssetExist = () => {
    return selectedGroup?.assets && selectedGroup?.assets.length > 0;
  };

  const groupStatusTabs = [
    {
      name: 'List',
      active: true,
      id: 0,
    },
    {
      name: 'Map',
      active: false,
      id: 1,
    },
    {
      name: 'Alarms',
      active: false,
      id: 2,
    },
  ];
  function onStausTabChanged() {
    // Implement functionality in the future
  }

  return (
    <>
      <div className='group-status-main-header'>
        <div className='group-status-group-name'>{wellGroupName}</div>
      </div>
      {isAssetExist() || assets?.loading ? (
        <>
          {assets?.loading ? (
            <span className='group-status-loader'>
              <Loader />
            </span>
          ) : (
            <>
              {isAssetExist() && (
                <div className='group-status-widget-container'>
                  <div className='flex flex-row flex-grow flex-shrink' style={{ width: '100%', height: '255px' }}>
                    <div className='flex flex-grow flex-shrink flex-column' style={{ width: '26%' }}>
                      <GroupStatusWidgets
                        loading={groupStatusRunStatusLoading}
                        failedToLoad={groupStatusRunStatusError}
                        widgetHeader='Downtime by run time status'
                        widgetChartData={groupWidgetData.downtimeByRunStatus?.values}
                        chartId='chart1'
                        chartValue={''}
                        isValueSumVisible={true}
                      />
                    </div>
                    <div
                      className='flex flex-grow flex-shrink flex-column'
                      style={{ paddingLeft: '1em', width: '27%' }}
                    >
                      <GroupStatusWidgets
                        loading={groupStatusWellsLoading}
                        failedToLoad={groupStatusWellsError}
                        widgetHeader='Downtime duration'
                        widgetChartData={groupWidgetData.downtimeByWells?.groupByDuration}
                        chartId='chart2'
                        chartValue={''}
                        isValueSumVisible={true}
                        csvData={groupWidgetData.downtimeByWells?.assets as any}
                      />
                    </div>
                    <div
                      className='flex flex-grow flex-shrink flex-column'
                      style={{ paddingLeft: '1em', width: '27%' }}
                    >
                      <GroupStatusWidgets
                        loading={groupStatusClassificatonsLoading}
                        failedToLoad={groupStatusClassificationsError}
                        widgetHeader='Classifications'
                        widgetChartData={classificationChartData}
                        chartId='chart3'
                        chartValue={classificationAssetCount}
                      />
                    </div>
                    <div
                      className='flex flex-grow flex-shrink flex-column'
                      style={{ paddingLeft: '1em', width: '19%', height: '100%' }}
                    >
                      <Alarms
                        loading={groupStatusAlarmsLoading}
                        failedToLoad={groupStatusAlarmsError}
                        value={alarmsValue}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className={isFullScreen ? 'group-status-full-screen' : ''}>
                <div className='group-status-container' style={isFullScreen ? { margin: '0px 0px' } : {}}>
                  <div className='text-and-badge'>
                    <div className='text'>Group status</div>
                    <div onClick={handleClick}>
                      <FullScreenIcon />
                    </div>
                  </div>
                  <div className='divider'></div>
                  <div
                    className={
                      isAssetExist() ? 'group-status-sub-header' : 'group-status-sub-header group-status-no-padding'
                    }
                  >
                    <div className={'tabs'}>
                      {!isAssetExist() && (
                        <div>
                          <UIButtonToolbar uiButtons={groupStatusTabs} buttonClicked={onStausTabChanged} />
                        </div>
                      )}
                    </div>
                    {isAssetExist() && (
                      <div className='options ui'>
                        <div className='group-status-icons-group'>
                          {assetId != '' && (
                            <Tooltip content='Well control' direction='top'>
                              <ControlActions
                                label='Well control options'
                                isWellEnabled={isWellEnabled}
                                canConfigWell={canConfigWell}
                                supportedControlActionIds={wellControlActionTypes.map((x) => x.id)}
                                buttonContent={
                                  <div data-testid='control-actions-button' className='well-controls-button'>
                                    <UISettings />
                                  </div>
                                }
                                assetId={assetId}
                                modeOptionViewPosition='left'
                              />
                            </Tooltip>
                          )}

                          <Tooltip content='Download' direction='top'>
                            <div className='download-btn'>
                              <UIDownloadButton onClick={handleDownloadClick} />
                            </div>
                          </Tooltip>
                        </div>

                        <Tooltip content='Available Views' direction='right'>
                          <div className='table-view-ddn'>
                            <Dropdown
                              assets={tableViews?.values?.map((tableView) => {
                                return {
                                  name: tableView.name,
                                  isSelected: selectedView && selectedView?.name === tableView.name,
                                };
                              })}
                              defaultOption={selectedView?.name}
                              showEyeIcon={false}
                              onChange={onSelectedViewChange}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {groupStatusLoading ? (
                    <Loader />
                  ) : (
                    groupStatus?.values?.columns &&
                    groupStatus?.values?.columns?.length > 0 && (
                      <AssetDetailsTable
                        columns={groupStatus?.values?.columns}
                        data={groupStatus?.values?.rows}
                        rawData={groupStatus?.values?.rowValues}
                        handleAssetDetailsRowClick={handleAssetDetailsRowClick}
                        groupName={groupName}
                        isFullScreen={isFullScreen}
                        clickedRowIndex={clickedRowIndex}
                      />
                    )
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className='w-full group-status-box-wrapper'>
            <div className='flex items-center justify-center h-screen'>
              <div className='text-center group-status-no-assets-block'>
                <img src={NoAssetsImage} />
                <h6>No assets found</h6>
                <p className='mt-3'>Your assets will show here once they are added to this group.</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GroupStatus;

import React from 'react';
import './TrendLabelItem.scss';

export interface TrendLabelItemProps {
  id: string;
  title: string;
  value: number;
  color: string;
}

function TrendLabelItem(props: TrendLabelItemProps) {
  const { title, value, color } = { ...props };

  return (
    <div className='trend-label-item-data'>
      <span className='trend-label-item-color' style={{ borderLeft: `2px solid ${color}` }} />
      <p className='trend-label-item-title'>{title} </p>
      <p className='trend-label-item-value'>{value} </p>
    </div>
  );
}

export default TrendLabelItem;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGLAnalysisCoordinatesAsync } from './GLAnalysisService';
import { CardCoordinate } from '../../models/Card';

interface Cards {
  [id: string]: Card;
}

interface Card {
  id: string;
  cardTrends: CardTrend[];
}

interface CardTrend {
  id: number;
  name: string;
  displayName: string;
  coordinates: CardCoordinate[];
  curveTypeId: number;
}

interface GLAnalysisCurveCoordinatesState {
  loading: boolean;
  cards: Cards;
  assetId: string;
  error: string | undefined;
}

interface GlAnalysisCoordinateParam {
  assetId: string;
  testDate: string;
  analysisResultId: string;
  analysisTypeId: string;
}

export const fetchGlAnalysisCurveCoordinatesInitialStateAsync = createAsyncThunk(
  'glAnalysisCurveCoordinatesSlice/fetchCurveCoordinatesInitialState',
  async (param: GlAnalysisCoordinateParam) => {
    const data = await fetchGLAnalysisCoordinatesAsync(
      param.assetId,
      param.testDate,
      param.analysisResultId,
      param.analysisTypeId,
    );
    return data;
  },
);

const initialState: GLAnalysisCurveCoordinatesState = {
  loading: false,
  cards: {},
  assetId: '',
  error: undefined,
};

export const glAnalysisCurveCoordinatesSlice = createSlice({
  name: 'glAnalysisCurveCoordinatesData',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlAnalysisCurveCoordinatesInitialStateAsync.pending, (state, action) => {
        state.loading = true;

        // If fetching data for new asset id then clear slice state.
        const assetId = action.meta.arg.assetId;
        const existingAssetId = state.assetId;

        if (assetId != existingAssetId) {
          Object.assign(state, initialState);
        }
      })
      .addCase(fetchGlAnalysisCurveCoordinatesInitialStateAsync.fulfilled, (state, action) => {
        const assetId = action.meta.arg.assetId;
        state.assetId = assetId;

        // GLR curves don't have a curveTypeId, so we need to add one
        if (action.payload && action.payload.values.length > 0) {
          action.payload.values.forEach((cardTrend: CardTrend) => {
            if (cardTrend?.displayName?.startsWith('GLR')) {
              cardTrend.curveTypeId = cardTrend.id;
            }
          });
        }

        const cardDate = action.meta.arg.testDate;
        const analysisTypeId = action.meta.arg.analysisTypeId;
        const analysisResultId = action.meta.arg.analysisResultId;
        const card: Card = {
          id: `${assetId}-${cardDate}-${analysisTypeId}-${analysisResultId}`,
          cardTrends: action.payload.values
            .filter((x: CardTrend) => x.coordinates != null && x.name != null && x.displayName != null)
            .map((x: CardTrend) => {
              return {
                id: x.id,
                name: x.name,
                coordinates: x.coordinates,
                curveTypeId: x.curveTypeId,
                displayName: x.displayName,
              };
            }),
        };

        if (state.cards[`${assetId}-${cardDate}-${analysisTypeId}-${analysisResultId}`] == null) {
          state.cards[`${assetId}-${cardDate}-${analysisTypeId}-${analysisResultId}`] = card;
        }

        state.loading = false;
      })
      .addCase(fetchGlAnalysisCurveCoordinatesInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setInitialState } = glAnalysisCurveCoordinatesSlice.actions;
export default glAnalysisCurveCoordinatesSlice.reducer;

import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useEffect } from 'react';
import './Toast.scss';

export default function ErrorToast({ message, isShowEnabled }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (isShowEnabled) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [isShowEnabled]);
  return (
    <>
      <div
        aria-live='assertive'
        className='z-10 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'
      >
        <div className='flex w-full flex-col items-center space-y-4 toast-top-position'>
          <Transition
            show={show}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 toaster-wrapper'>
              <div className='p-4 h-full'>
                <div className='flex items-start items-center justify-center h-full'>
                  <div className='flex-shrink-0'>
                    <XCircleIcon className='h-6 w-6 text-red-400' aria-hidden='true' />
                  </div>
                  <div className='ml-3 w-0 flex-1 pt-0.5'>
                    <p className='text-md font-medium text-gray-900'>{message}</p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AssetStatus from './features/asset-status/AssetStatus';
import AssetAnalysis from './features/asset-analysis/AssetAnalysis';
import Asset from './features/asset/components/Asset';
import GroupStatus from './features/group-status/GroupStatus';
import './App.css';
import { Layout } from './layouts/PageLayout';
import OnboardingShell from './onboarding/OnboardingShell';
import { DefaultView } from './onboarding/default-view/DefaultView';
import AuthComponent from './features/common/AuthComponent';
import ServerError from './features/server-error/ServerError';
import ADLoginError from './features/adlogin-error/ADLoginError';
import Login from './features/common/Login/Login';
import { setFavicon } from './utilities/SetFavicon';

const App: React.FC = () => {
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const applyTheme = () => {
      setFavicon();
    };

    applyTheme();

    mediaQuery.addEventListener('change', applyTheme);

    return () => {
      mediaQuery.removeEventListener('change', applyTheme);
    };
  }, []);

  return (
    <BrowserRouter>
      <div data-testid='router'>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<AuthComponent component={Layout} />}>
            <Route path='/asset/' element={<Asset />}>
              <Route path='status/:assetId/:assetGroupName' element={<AuthComponent component={AssetStatus} />} />
              <Route path='analysis/:assetId/:assetGroupName' element={<AuthComponent component={AssetAnalysis} />} />
            </Route>
            <Route path='/group/status/:groupName' element={<AuthComponent component={GroupStatus} />} />
            <Route path='/defaultview' element={<AuthComponent component={DefaultView} />} />
          </Route>
          <Route path='/onboarding' element={<AuthComponent component={OnboardingShell} />} />
          <Route path='/servererror' element={<ServerError />} />
          <Route path='/adloginerror' element={<ADLoginError />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;

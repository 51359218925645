import React, { lazy, Suspense, useState } from 'react';
import { ReactComponent as ChampionXLogo } from '../images/championx-logo-reverse.svg';
import { ReactComponent as ArrowLeft } from '../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../images/arrow-right.svg';
import './OnboardingShell.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/storeHooks';
import OnboardingSteps from './OnboardingSteps';
import { DefaultView } from './default-view/DefaultView';
import UserService from '../services/user/user.service';
import { UserPreference } from '../services/user/user.service';
import WhatsNewImage from '../features/common/WhatsNewImage/WhatsNewImage';

const OnboardingShell = () => {
  const assetGroups = useAppSelector((state) => state.signInDefaultView);

  const BeyondStatusLazy = lazy(() => import('../features/common/BeyondStatusImage/BeyondStatusImage'));
  const AwesomeAnalysisLazy = lazy(() => import('../features/common/AwesomeAnalysisImage/AwesomeAnalysisImage'));

  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const totalSteps = 4;

  const handleNext = () => {
    if (step === totalSteps) {
      return;
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (step === 1) {
      return;
    } else {
      setStep((prev) => prev - 1);
    }
  };

  const handleGoToDashboard = async () => {
    const params: UserPreference = {
      Property: 'DefaultPage',
      Group: 'Landing',
      Value: '',
    };

    const selectedAssetId = assetGroups?.selectedGroup?.assets.find((asset) => asset.isSelected)?.assetId;
    if (selectedAssetId) {
      params.Value = `{"PageType": "Asset", "Value" : "${assetGroups?.selectedGroup?.assetGroupName}|${selectedAssetId}"}`;
    } else if (assetGroups?.selectedGroup) {
      params.Value = `{"PageType": "Group", "Value" : "${assetGroups?.selectedGroup?.assetGroupName}"}`;
    } else {
      navigate('/');
    }

    await UserService.saveLandingPage(params).then(() => {
      navigate('/');
    });
  };

  const whatsNew =
    'Fresh new look and layout\nCleaner, more organized, and new features\nNew data visualizations and interactions\nHolistic asset analysis';

  const awesomeAnalysis =
    'Analyze your wells with side-by-side viewing\nInteract with large data sets in Card viewer\nRich chart interactions with History Graph\nNew sparkline trend groups';

  const beyondStatus =
    'Alarm-focused workflows to acknowledge and support\nGet a new perspective on the field and proactively address potential issues';

  return (
    <div data-testid='onboarding-shell' className='onboarding-shell'>
      <div className='onboarding-shell-left'>
        <div className='onboarding-shell-left-container'>
          <div className='onboarding-shell-content'>
            <div className='onboarding-shell-header'>
              <div className='onboarding-shell-header-logo-container'>
                <ChampionXLogo />
              </div>
            </div>
            {step == 1 && <DefaultView />}
            <div className='onboarding-shell-steps'>
              {step === 2 && (
                <OnboardingSteps
                  data-testid='onboarding-steps-description'
                  header="What's new?"
                  description={whatsNew}
                />
              )}
              {step === 3 && (
                <OnboardingSteps
                  data-testid='onboarding-steps-description'
                  header='Awesome analysis'
                  description={awesomeAnalysis}
                />
              )}
              {step === 4 && (
                <OnboardingSteps
                  data-testid='onboarding-steps-description'
                  header='Beyond status'
                  description={beyondStatus}
                />
              )}
            </div>
            <div className='onboarding-shell-navigation-buttons'>
              <button className={`onboarding-shell-back ${step === 1 ? 'hidden' : ''}`} onClick={handleBack}>
                <ArrowLeft className={'arrowleft'} />
                Back
              </button>
              <button
                className={`onboarding-shell-skip ${step === 1 || step === totalSteps ? 'hidden' : ''}`}
                onClick={handleGoToDashboard}
              >
                Skip intro
              </button>
              <button className='onboarding-shell-continue' onClick={step === 4 ? handleGoToDashboard : handleNext}>
                {step === 4 ? 'Go to my dashboard' : 'Continue'}
                <ArrowRight />
              </button>
            </div>
          </div>
          <div className='onboarding-shell-footer'>© ChampionX {new Date().getFullYear()}</div>
        </div>
      </div>
      <div className='onboarding-shell-right'>
        <div className='onboarding-shell-right'>
          <div className='onboarding-shell-image' data-testid='whats-new'>
            {step <= 2 && (
              <Suspense fallback={<div />}>
                <WhatsNewImage />
              </Suspense>
            )}
            {step === 3 && (
              <Suspense fallback={<div />}>
                <AwesomeAnalysisLazy />
              </Suspense>
            )}
            {step === 4 && (
              <Suspense fallback={<div />}>
                <BeyondStatusLazy />
              </Suspense>
            )}
          </div>
          <p className='onboarding-shell-right-header'>Welcome to your new dashboard</p>
          <p className='onboarding-shell-right-text'>Super charging group views with dashboards</p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingShell;

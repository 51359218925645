import React from 'react';

const ChevronDown = ({ color = '#687760' }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 9L12 15L18 9' stroke={color} stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  );
};

export default ChevronDown;

import React, { useEffect } from 'react';
import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Card, CardCoordinate, RGBColor, SelectedCardTrend } from '../../../../models/Card';
import './PerformanceCurveChart.scss';
import { CurveType } from '../../enums/CurveType';
import { useAppSelector } from '../../../../hooks/storeHooks';

interface PerformanceCurveChartProps {
  selectedCardTrends?: SelectedCardTrend[];
  cards?: Card[];
}

const PerformanceCurveChart: React.FC<PerformanceCurveChartProps> = (props: PerformanceCurveChartProps) => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';

  const minDefault = 0;
  const maxDefault = 1;

  const colors = [
    {
      id: 1,
      name: 'Production performance curve',
      color: '#884dd5',
    },
    {
      id: 2,
      name: 'Pressure Performance Curve',
      color: '#FF0000',
    },
    {
      id: 27,
      name: 'Flowing bottomhole pressure performance curve',
      color: '#f97066',
    },
    {
      id: -1,
      name: 'Coleman Crit flowrate at surface',
      color: '#4294ff',
    },
    {
      id: -4,
      name: 'Coleman Crit flowrate at injection depth',
      color: '#32d583',
    },
    {
      id: -5,
      name: 'Mod* Thornhill-Craver Valve CV',
      color: '#98ceca',
    },
    {
      id: -2,
      name: 'Production Operating Point',
      color: '#0000FF',
    },
    {
      id: -3,
      name: 'Pressure Operating Point',
      color: '#FF0000',
    },
  ];

  useEffect(() => {
    const root = am5core.Root.new('performance-curve-chart');

    // create chart.
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelY: 'zoomXY',
        maxTooltipDistance: -1,
        width: am5core.percent(100),
      }),
    );

    // create y axis.
    const yAxis = createYAxis();

    // create opposite y axis.
    const oppositeYAxis = createOppositeYAxis();

    // create x axis.
    const xAxis = createXAxis();

    calculateMinMaxForAxes();

    // plot all series.
    props.selectedCardTrends?.forEach((selectedCardTrend) => {
      props.cards?.forEach((card) => {
        card.cardTrends?.forEach((cardTrend) => {
          if (cardTrend.curveTypeId === selectedCardTrend.id && cardTrend.displayName === selectedCardTrend.label) {
            if (cardTrend.curveTypeId == CurveType.ProductionPerformance) {
              createPerformanceCurveSeries(cardTrend.displayName, card.color, cardTrend.coordinates);
            } else if (cardTrend.displayName.includes('Point')) {
              createBulletSeries(cardTrend.displayName, cardTrend.curveTypeId, card.color, cardTrend.coordinates);
            } else {
              createSeries(cardTrend.displayName, card.color, cardTrend.coordinates, cardTrend.curveTypeId, yAxis);
            }
          }
        });
      });
    });

    root.setThemes([am5themes_Animated.new(root)]);

    // create cursor.
    const customCursor = am5xy.XYCursor.new(root, {});
    chart.set('cursor', customCursor);

    return () => {
      root.dispose();
    };

    function createYAxis() {
      const axisRendererY = am5xy.AxisRendererY.new(root, {
        minGridDistance: 50,
      });
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: axisRendererY,
          min: minDefault,
          max: maxDefault,
          strictMinMax: true,
        }),
      );
      axisRendererY.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      yAxis.children.unshift(
        am5core.Label.new(root, {
          text: 'Liquid production rate (bpd)',
          x: am5core.p50,
          centerX: am5core.p50,
          y: am5core.p50,
          centerY: am5core.p50,
          rotation: 270,
          marginRight: 15,
          fontSize: 12,
          fontWeight: '400',
          fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
        }),
      );

      const yAxisRenderer = yAxis.get('renderer');
      yAxisRenderer.grid.template.setAll({
        strokeOpacity: 1,
        stroke: am5core.color('#D0D8DD'),
        strokeWidth: 1,
      });

      return yAxis;
    }

    function createOppositeYAxis() {
      const rendererY = am5xy.AxisRendererY.new(root, { minGridDistance: 50, opposite: true });
      const oppositeYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: rendererY,
          min: minDefault,
          max: maxDefault,
          strictMinMax: true,
        }),
      );

      rendererY.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      const label = am5core.Label.new(root, {
        text: 'Pressure (psi)',
        rotation: -90,
        fontSize: 12,
        fontWeight: '400',
        fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      });

      label.set('y', am5core.percent(70));
      label.set('x', am5core.percent(100));

      oppositeYAxis.children.unshift(label);

      oppositeYAxis.get('renderer').grid.template.setAll({ strokeWidth: 0.1 });

      return oppositeYAxis;
    }

    function createXAxis() {
      const axisRendererX = am5xy.AxisRendererX.new(root, { minGridDistance: 50 });
      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: axisRendererX,
          min: minDefault,
          max: maxDefault,
          strictMinMax: true,
        }),
      );
      axisRendererX.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      xAxis.children.push(
        am5core.Label.new(root, {
          text: 'Injected gas rate (mscf/d)',
          x: am5core.p50,
          centerX: am5core.p50,
          fontSize: 12,
          fontWeight: '400',
          fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
        }),
      );

      const xAxisRenderer = xAxis.get('renderer');
      xAxisRenderer.grid.template.setAll({
        strokeDasharray: [2.5],
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: am5core.color('#D0D8DD'),
      });

      return xAxis;
    }

    function calculateMinMaxForAxes() {
      const allYValues: number[] = [];
      const allXValues: number[] = [];
      const allXValuesOperatingPoints: number[] = [];
      const allYValuesOpposite: number[] = [];
      const allProductionPerformanceCurveYApexXCoordinate: number[] = [];

      props.selectedCardTrends?.forEach((selectedCardTrend) => {
        props.cards?.forEach((card) => {
          card.cardTrends?.forEach((cardTrend) => {
            if (cardTrend.coordinates.length === 0 || cardTrend.curveTypeId !== selectedCardTrend.id) {
              return;
            }

            // accumulate all Y values for opposite Y axis
            if (
              cardTrend.curveTypeId === CurveType.FlowingBottomholePressure ||
              cardTrend.curveTypeId === CurveType.PressurePerformance ||
              cardTrend.curveTypeId === CurveType.PressureOperatingPoint
            ) {
              allYValuesOpposite.push(...cardTrend.coordinates.map((coord) => coord.y));
            }

            // accumulate all Y values for Y axis
            if (
              cardTrend.curveTypeId === CurveType.ProductionOperatingPoint ||
              cardTrend.curveTypeId === CurveType.ProductionPerformance
            ) {
              allYValues.push(...cardTrend.coordinates.map((coord) => coord.y));
            }

            // accumulate all X values for X axis
            allXValues.push(...cardTrend.coordinates.map((coord) => coord.x));

            // accumulate all X values for operating points
            if (
              cardTrend.curveTypeId === CurveType.ProductionOperatingPoint ||
              cardTrend.curveTypeId === CurveType.PressureOperatingPoint
            ) {
              allXValuesOperatingPoints.push(...cardTrend.coordinates.map((coord) => coord.x));
            }

            // accumulate all X values for Production Performance Y Apex
            if (cardTrend.curveTypeId === CurveType.ProductionPerformance) {
              const productionPerformanceCurveCoordinatesSorted =
                card.cardTrends
                  ?.find((trend) => trend.curveTypeId === 1)
                  ?.coordinates.map((c) => c)
                  .sort((a, b) => b.y - a.y) ?? [];

              allProductionPerformanceCurveYApexXCoordinate.push(productionPerformanceCurveCoordinatesSorted[0].x);
            }
          });
        });
      });

      const maxX =
        Math.max(...allProductionPerformanceCurveYApexXCoordinate) > Math.max(...allXValuesOperatingPoints)
          ? Math.max(...allProductionPerformanceCurveYApexXCoordinate)
          : Math.max(...allXValuesOperatingPoints);

      // Update axis bounds
      yAxis.set('min', minDefault);
      yAxis.set('max', allYValues?.length > 0 ? Math.max(...allYValues) * 1.2 : maxDefault);
      xAxis.set('min', minDefault);
      xAxis.set('max', Number.isFinite(maxX) && maxX > 0 ? maxX * 1.25 : maxDefault);
      oppositeYAxis.set('max', allYValuesOpposite?.length > 0 ? Math.max(...allYValuesOpposite) * 1.1 : maxDefault);
    }

    function createSeries(
      seriesName: string,
      color: RGBColor | undefined,
      coordinates: CardCoordinate[],
      curveTypeId: number,
      yAxis: am5xy.ValueAxis,
    ) {
      const tooltip = am5core.Tooltip.new(root, {
        labelText: '{name}\nX: {valueX}\nY: {valueY}',
        getFillFromSprite: false,
        getLabelFillFromSprite: false,
        autoTextColor: false,
      });

      let seriesYAxis = yAxis;

      // Check curve type and assign the appropriate Y-axis
      if (curveTypeId === CurveType.ProductionPerformance) {
        seriesYAxis = yAxis;
      } else if (
        curveTypeId === CurveType.PressureOperatingPoint ||
        curveTypeId === CurveType.PressurePerformance ||
        curveTypeId === CurveType.FlowingBottomholePressure
      ) {
        seriesYAxis = oppositeYAxis;
      }

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: seriesYAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color
            ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`)
            : am5core.color(colors.find((color) => color.id === curveTypeId)?.color || '#000000'),
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: 4,
      });

      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;
      tooltip.get('background')?.set('fill', series.get('stroke'));
      tooltip.label.setAll({
        fill:
          seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
            ? am5core.color('#000')
            : am5core.color('#fff'),
        fontSize: 12,
        fontWeight: '400',
      });

      series.data.setAll(coordinates);
    }

    function createPerformanceCurveSeries(
      seriesName: string,
      color: RGBColor | undefined,
      coordinates: CardCoordinate[],
    ) {
      const tooltip = am5core.Tooltip.new(root, {
        labelText: '{name}\nX: {valueX}\nY: {valueY}',
        getFillFromSprite: false,
        getLabelFillFromSprite: false,
        autoTextColor: false,
      });

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`) : am5core.color('#884dd5'),
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: 4,
      });

      series.strokes.template.set('strokeDasharray', [3, 3]);

      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;
      tooltip.get('background')?.set('fill', series.get('stroke'));
      tooltip.label.setAll({
        fill:
          seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
            ? am5core.color('#000')
            : am5core.color('#fff'),
        fontSize: 12,
        fontWeight: '400',
      });

      series.data.setAll(coordinates);
    }

    function createBulletSeries(
      seriesName: string,
      curveTypeId: number,
      color: RGBColor | undefined,
      coordinates: CardCoordinate[] = [],
    ) {
      const tooltip = am5core.Tooltip.new(root, {
        labelText: '{name}\nX: {valueX}\nY: {valueY}',
        getFillFromSprite: false,
        getLabelFillFromSprite: false,
        autoTextColor: false,
      });

      const yAxisForPoint = curveTypeId === CurveType.PressureOperatingPoint ? oppositeYAxis : yAxis;

      const series = chart.series.push(
        am5xy.XYSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxisForPoint,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color
            ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`)
            : am5core.color(colors.find((color) => color.id === curveTypeId)?.color || '#000000'),
        }) as am5xy.XYSeries,
      );

      series.bullets.push(function (root) {
        return am5core.Bullet.new(root, {
          sprite: am5core.Rectangle.new(root, {
            width: 7,
            height: 7,
            fill: seriesColor,
            centerX: am5core.p50,
            centerY: am5core.p50,
          }),
        });
      });
      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;

      tooltip.get('background')?.set('fill', seriesColor);
      tooltip.label.setAll({
        fill:
          seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
            ? am5core.color('#000')
            : am5core.color('#fff'),
        fontSize: 12,
        fontWeight: '400',
      });

      series.data.setAll(coordinates);
    }
  }, [props.cards?.length, props.selectedCardTrends?.length, isDarkMode]);

  return <div id='performance-curve-chart' className='performance-curve-chart'></div>;
};

export default PerformanceCurveChart;

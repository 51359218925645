import React from 'react';
import { DisplayStatusStateCssClassMap } from '../DisplayStatusStateCssClassMap';
import RefreshIcon from '../icons/RefreshIcon';
import { OverLayFields } from '../models/OverlayFields';
import { useAppSelector } from '../../../hooks/storeHooks';
import { FacilityTagData } from '../../../models/FacilityTagData';
import FacilityTagGroupTable from './FacilityTagGroupTable';
import { DiagramTypes } from '../models/DiagramTypes';
import { IImageOverlayModel } from '../../../models/ImageOverlayModel';
import ValveControlTables from './ValveControlTables';
import { ValveControlData } from '../../../models/ValveControlData';
import PIDTable from './PIDTable';
import { PIDData } from '../../../models/PIDData';
import Loader from '../../common/Loader/Loader';

interface TableDiagramProps {
  imageOverlays: IImageOverlayModel[];
  facilityTagData: FacilityTagData[];
  valveControlData: ValveControlData;
  pidData: PIDData;
  onRefresh: () => void;
  diagramType: number;
}

const diagramTypeMappings = {
  [DiagramTypes.Facility]: {
    headerTitle: 'Facilities',
    title: 'Facility information',
  },
  [DiagramTypes.RegisterView]: {
    headerTitle: 'Default',
    title: 'Default information',
  },
  [DiagramTypes.ValveControl]: {
    headerTitle: 'Valve control',
    title: 'Device information',
  },
  [DiagramTypes.PID]: {
    headerTitle: 'PID Diagram',
    title: 'Device Information',
  },
};

const TableDiagram = ({
  imageOverlays,
  facilityTagData,
  valveControlData,
  pidData,
  onRefresh,
  diagramType,
}: TableDiagramProps) => {
  const isLoading = useAppSelector((state) => state.rodliftwellstatus.loading);

  const { headerTitle, title } = diagramTypeMappings[diagramType] || {
    headerTitle: 'Default',
    title: 'Default information',
  };

  const lastGoodScan =
    diagramType === DiagramTypes.ValveControl || diagramType === DiagramTypes.PID
      ? imageOverlays.find((x) => x.overlayField === OverLayFields.LastGoodScan)
      : null;
  const commStatus =
    diagramType === DiagramTypes.ValveControl || diagramType === DiagramTypes.PID
      ? imageOverlays.find((x) => x.overlayField === OverLayFields.CurrentCommunicationStatus)
      : null;

  const getCssClassByOverlayField = (displayState: number, overlayField: number) => {
    switch (overlayField) {
      case OverLayFields.FacilityComms:
      case OverLayFields.FacilityEnabled:
        return DisplayStatusStateCssClassMap.get(displayState);
      default:
        return '';
    }
  };

  return (
    <div
      className='flex h-full card asset-status-card flex-column'
      style={{
        opacity: isLoading ? 0.5 : 1,
      }}
    >
      <div className='w-full header'>
        <div className='flex flex-row justify-between w-full'>
          <span className='title'>{headerTitle}</span>
          <div className='asset-status-refresh-icon' onClick={onRefresh}>
            <RefreshIcon />
          </div>
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        >
          <Loader />
        </div>
      )}
      <div className='flex flex-grow w-full h-full gap-4 p-6 p-relative'>
        <div className='w-[27%] flex flex-column gap-3'>
          {/* Last good scan */}
          {lastGoodScan ? (
            <div className='table-diagram-single-value-container'>
              <span>{lastGoodScan.dataValues[0].label}</span>
              <span
                className={`facility-display-state-${DisplayStatusStateCssClassMap.get(
                  lastGoodScan.dataValues[0].displayState,
                )}`}
              >
                {lastGoodScan.dataValues[0].value}
              </span>
            </div>
          ) : null}
          {/* Comm status */}
          {commStatus ? (
            <div className='table-diagram-single-value-container'>
              <span>{commStatus.dataValues[0].label}</span>
              <span
                className={`facility-display-state-${DisplayStatusStateCssClassMap.get(
                  commStatus.dataValues[0].displayState,
                )}`}
              >
                <div className='status-indicator-container'>
                  {commStatus.dataValues[0].displayState == 1 && (
                    <div
                      className={`status-indicator-facility status-indicator ${DisplayStatusStateCssClassMap.get(
                        commStatus.dataValues[0].displayState,
                      )}`}
                    />
                  )}
                  {commStatus.dataValues[0].value}
                </div>
              </span>
            </div>
          ) : null}
          <div className='flex w-full gap-2 p-4 flex-column table-diagram-multi-value-container'>
            <div className='w-full mb-2'>
              <span className='asset-status-container-title-text'>{title}</span>
            </div>
            {imageOverlays
              .filter((x) => x.dataValues?.length > 0)
              .map((imageOverlay, index, arr) => {
                if (imageOverlay.dataValues.length === 0) {
                  return null;
                }

                const useStatusIndicator =
                  imageOverlay.dataValues[0].displayState == 1 &&
                  imageOverlay.overlayField === OverLayFields.CurrentCommunicationStatus;

                const shouldUseNormalDisplayState =
                  imageOverlay.overlayField === OverLayFields.CurrentCommunicationStatus ||
                  imageOverlay.overlayField === OverLayFields.FacilityEnabled ||
                  imageOverlay.overlayField === OverLayFields.RunStatus;

                if (
                  imageOverlay.overlayField === OverLayFields.LastGoodScan ||
                  imageOverlay.overlayField === OverLayFields.CurrentCommunicationStatus
                ) {
                  return null;
                }

                return (
                  <div
                    key={index}
                    className={`asset-status-key-value-text w-full h-12 flex flex-row justify-between items-center px-2 ${
                      arr.length - 1 === index ? '' : 'bottom-divider'
                    }`}
                  >
                    <span>{imageOverlay.dataValues[0].label}</span>
                    <span
                      className={`facility-display-state-${getCssClassByOverlayField(
                        imageOverlay.dataValues[0].displayState,
                        imageOverlay.overlayField,
                      )}`}
                    >
                      <div className='status-indicator-container'>
                        {useStatusIndicator && shouldUseNormalDisplayState && (
                          <div
                            className={`status-indicator-facility status-indicator ${DisplayStatusStateCssClassMap.get(
                              imageOverlay.dataValues[0].displayState,
                            )}`}
                          />
                        )}
                        {imageOverlay.dataValues[0].value}
                      </div>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        <div className='w-[73%] flex'>
          {diagramType === DiagramTypes.Facility || diagramType === DiagramTypes.RegisterView ? (
            <FacilityTagGroupTable facilityTagData={facilityTagData} />
          ) : diagramType === DiagramTypes.ValveControl ? (
            <ValveControlTables valveControlData={valveControlData} />
          ) : diagramType === DiagramTypes.PID ? (
            <PIDTable pidData={pidData} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TableDiagram;

import React from 'react';
import './ChartSelect.scss';

interface ChartSelectProps {
  chartOptions: { id: number; label: string }[];
  selectedChartId: number;
  setSelectedChartId: (id: number) => void;
}

const ChartSelect: React.FC<ChartSelectProps> = ({ chartOptions, selectedChartId, setSelectedChartId }) => {
  return (
    <div className='chart-select'>
      <select
        className='chart-select__select'
        value={selectedChartId}
        onChange={(e) => setSelectedChartId(Number(e.target.value))}
      >
        {chartOptions?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ChartSelect;

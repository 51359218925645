import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import ChampionXLogoDT from '../../../images/championx-logo-png.png';
import WhatsNew from '../../../images/whats-new-png.png';
import '../../../onboarding/OnboardingShell.scss';

const UsernameInput = lazy(() => import('./UsernameInput'));
const PasswordInput = lazy(() => import('./PasswordInput'));
import AuthService from '../../../services/authentication/auth.service';
import { LoginModel } from '../../../models/Login';
import './Login.scss';

const Loader = lazy(() => import('../Loader/Loader'));

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (AuthService.isUserLoggedIn() === true) {
      navigate('/');
    }
  }, []);

  const handleUsernameChange = (value: string) => {
    setUsername(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoginError('');
    // Perform login logic
    const userCredentials: LoginModel = {
      grantType: 'form',
      userName: username,
      passwordHash: password,
    };
    if (username !== '' && password !== '') {
      setIsLoading(true);
      await AuthService.login(userCredentials)
        .then((response) => {
          if (response === 'firstTimeLogin') {
            setIsLoading(false);
            navigate('/onboarding');
          } else if (response === 'success') {
            setIsLoading(false);
            navigate('/');
          } else {
            setLoginError('Invalid login. If needed, please contact your administrator.');
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <div data-testid='onboarding-shell' className='onboarding-shell'>
        <div className='onboarding-shell-left'>
          <div className='onboarding-shell-left-container'>
            <div className='onboarding-shell-content'>
              <div className='onboarding-shell-header'>
                <div className='onboarding-shell-header-logo-container'>
                  <img src={ChampionXLogoDT} alt='ChampionX Logo' className='onboarding-shell-header-logo' />
                </div>
              </div>
              <div className='login-container'>
                <div className='login-title'>Log in</div>
                <div className='login-sub-title'>Welcome back! Please enter your details.</div>
                <form data-testid='submit' onSubmit={handleSubmit}>
                  <UsernameInput onUsernameChange={handleUsernameChange} />
                  <PasswordInput onPasswordChange={handlePasswordChange} />
                  {loginError && <div style={{ color: 'red' }}>{loginError}</div>}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <button className='login-btn' type='submit'>
                      Sign in
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className='onboarding-shell-footer'>© ChampionX {new Date().getFullYear()}</div>
          </div>
        </div>
        <div className='onboarding-shell-right'>
          <div className='onboarding-shell-right'>
            <div className='onboarding-shell-image' data-testid='whats-new'>
              <img src={WhatsNew} alt='Whats New' />
            </div>
            <p className='onboarding-shell-right-header'>Welcome to XSPOC</p>
            <p className='onboarding-shell-right-text'>Sign in to explore changes we’ve made.</p>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default Login;

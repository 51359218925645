import React, { useEffect } from 'react';
import { PromptsCheckbox } from '../../features/common/PromptsCheckbox/PromptsCheckbox';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks';
import {
  fetchInitialStateAsync,
  updateGroupAssetsDisplay,
  updateAssetDisplay,
  updateChildGroupAssetDisplay,
  Asset,
  resetAllSelected,
} from './SignInDefaultViewSlice';
import './DefaultView.scss';

export const DefaultView = () => {
  const dispatch = useAppDispatch();
  const assetGroups = useAppSelector((state) => state.signInDefaultView.groups);

  useEffect(() => {
    dispatch(fetchInitialStateAsync());
  }, [dispatch]);

  const resetSelectedGroupsAssets = () => {
    dispatch(resetAllSelected());
  };

  const handleGroupSelection = (
    groupId: number | undefined,
    assetId: string | undefined,
    childGroupId: number | undefined,
    groupName: string | undefined,
  ) => {
    resetSelectedGroupsAssets();

    if (childGroupId) {
      dispatch(
        updateGroupAssetsDisplay({
          groupId: groupId,
          assetId: assetId,
          searchString: undefined,
          childGroupId: childGroupId,
          groupName: groupName,
        }),
      );
    } else {
      dispatch(
        updateGroupAssetsDisplay({
          groupId: groupId,
          assetId: assetId,
          searchString: undefined,
          childGroupId: undefined,
          groupName: groupName,
        }),
      );
    }
  };

  const handleIconClick = (groupId: number | undefined, childGroupId: number | undefined, groupName) => {
    if (childGroupId) {
      dispatch(
        updateChildGroupAssetDisplay({
          groupId: groupId,
          assetId: undefined,
          searchString: undefined,
          childGroupId: childGroupId,
          groupName: groupName,
        }),
      );
    } else {
      dispatch(
        updateAssetDisplay({
          groupId: groupId,
          assetId: undefined,
          searchString: undefined,
          childGroupId: undefined,
          groupName: groupName,
        }),
      );
    }
  };

  const displayAssets = (groupId: number, assets: Asset[], childGroupId, groupName: string, indentLevel) => {
    const assetClass = childGroupId ? 'expanded-asset child-group-asset' : 'expanded-asset';
    return assets.map((asset) => {
      return (
        <div className={assetClass} style={{ paddingLeft: `${(indentLevel + 1) * 25}px` }}>
          <PromptsCheckbox
            isChecked={asset?.isSelected}
            Label={asset?.assetName}
            showIcon={false}
            onItemSelect={() => {
              handleGroupSelection(groupId, asset.assetId, childGroupId, groupName);
            }}
          />
        </div>
      );
    });
  };

  const renderGroup = (group, indentLevel) => {
    return (
      <>
        <div className='checkbox-container' style={{ paddingLeft: `${(indentLevel + 1) * 20}px` }}>
          <PromptsCheckbox
            isChecked={group?.isSelected}
            Label={group?.assetGroupName}
            key={group?.assetGroupName}
            showIcon={true}
            onItemSelect={() => {
              handleGroupSelection(group?.id, undefined, undefined, group?.assetGroupName);
            }}
            onPlusIconClick={() => {
              handleIconClick(group?.id, undefined, group?.assetGroupName);
            }}
            showAssets={group?.showAssets}
          />
        </div>
        {group?.showAssets && group?.childGroups.map((childGroup) => renderGroup(childGroup, indentLevel + 1))}
        {group?.showAssets && displayAssets(group.id, group.assets, undefined, group?.assetGroupName, indentLevel + 1)}
      </>
    );
  };
  return (
    <div className='header-and-form'>
      <div className='default-view-header'>
        <h2 className='setup-heading'>Setup your default view</h2>
        <p className='setup-message'>
          Select the Asset or Asset Group that will load by default when you launch XSPOC. Don’t worry, this default can
          be changed later.
        </p>
      </div>
      <div className='default-view-container'>
        {assetGroups && assetGroups.length > 0 && assetGroups[0].childGroups.map((group) => renderGroup(group, 0))}
      </div>
    </div>
  );
};

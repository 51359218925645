import React, { useEffect, useState } from 'react';
import Plus from '../../../images/plus.svg';
import Minus from '../../../images/minus.svg';
import Check from '../../../images/check-mark.svg';
import './PromptsCheckbox.scss';

interface PromptsCheckboxProps {
  showIcon: boolean;
  isChecked: boolean | undefined;
  Label: string | undefined;
  onItemSelect: () => void | undefined;
  onPlusIconClick: () => void | undefined;
  showAssets: boolean;
}

export const PromptsCheckbox = (props: PromptsCheckboxProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [icon, setIcon] = useState(Plus);

  useEffect(() => {
    setIconState(props.showAssets);
  }, [props.showAssets]);

  const setIconState = (toggle: boolean) => {
    if (toggle) {
      setIcon(Minus);
    } else {
      setIcon(Plus);
    }
  };

  const handleGroupExpand = () => {
    setIconState(!isExpanded);
    setIsExpanded(!isExpanded);
    props.onPlusIconClick();
  };

  const handleCheckboxChange = () => {
    props.onItemSelect();
  };

  return (
    <>
      {props.showIcon && <img className='plus-icon' src={icon} onClick={handleGroupExpand} alt='plus-icon' />}
      <div className='custom-checkbox-parent'>
        <div>
          {props.isChecked ? (
            <label
              data-testid='custom-checkbox'
              htmlFor='customCheckbox'
              className='custom-checkbox checked'
              onClick={handleCheckboxChange}
            >
              <img className='check-mark' src={Check} alt='check-icon' />
            </label>
          ) : (
            <label
              data-testid='custom-checkbox'
              htmlFor='customCheckbox'
              className='custom-checkbox'
              onClick={handleCheckboxChange}
            ></label>
          )}
        </div>
        <div className='prompt-checkbox-label'>{props.Label}</div>
      </div>
    </>
  );
};

import React, { useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../../images/settings-01.svg';
import './UIEyeAndFilterButtons.scss';

function UISettingsButton() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className='ui-settings-button'
      onClick={() => {
        setIsActive(!isActive);
      }}
    >
      <SettingsIcon />
      <span className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
    </div>
  );
}

export default UISettingsButton;

import React, { useState, useEffect, startTransition } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './NavBar.scss';
import NavigationDrawerItem from './NavigationDrawerItem';
import Tooltip from '../../common/tooltip/ToolTip';
import { ReactComponent as NotificationIcon } from '../../../images/bell-01.svg';
import NotificationDrawerItem from './NotificationDrawerItem';
import { updateSocketId, setInitialState } from '../AssetGroupsSlice';
import {
  setExecuteControlActionOnReturnSuccessful,
  setExecuteControlActionOnReturnError,
} from '../../asset/AssetDataSlice';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { getWebSocketURI } from '../../../utilities/BaseURLUtility';
import { WellControlNotification, addNotificationsFromWellControlTransaction } from '../NotificationsSlice';
import AuthService from '../../../services/authentication/auth.service';
import { updateSearchGroups } from '../AssetGroupsSlice';
import ConnexiaLogo from '../../../images/connexia-logo.svg';
import { ReactComponent as AssetListIcon } from '../../../images/dataflow-02.svg';
import { ReactComponent as CollapseIcon } from '../../../images/log-out-02.svg';
import { setInitialState as setInitialCustomViewState } from '../../../features/asset-analysis/TrendGroupLabel/TrendGroupLabelSlice.tsx';
import { setInitialState as setInitialTrendLibraryState } from '../../../features/asset-analysis/TrendGroupLabel/TrendGroupLabelSlice.tsx';
import { setInitialState as setInitialCoordinateState } from '../../../features/data-history/TrendLibrary/TrendCoordinateSlice.tsx';
import { updateCheckBoxFlag } from './../../../features/data-history/TrendLibrary/TrendLibrarySlice.tsx';
import { setInitialState as setInitialCustomViews } from '../../../features/data-history/TrendLibrary/DefaultTrendsSlice.tsx';
import { useAppSelector } from '../../../hooks/storeHooks';
import useClickOutside from '../hook/useClickOutside.ts';

// These numbers map to the value for socket response  expected by well control on the backend.
enum Action {
  Unknown = 0,
  ProcessDeviceScan = 1,
  StartWell = 2,
  StopWell = 3,
  IdleWell = 4,
  ClearAlarms = 5,
  CommunicationLog = 18,
  ConstantRunMode = 6,
  PocMode = 7,
  PercentTimerMode = 8,
  SetClock = 9,
  UpdatePocData = 10,
  GetCard = 11,
  DownloadEquipment = 12,
  UploadEquipment = 13,
  EnablePid = 14,
  DisablePid = 15,
  GetData = 16,
  GetCardDirect = 17,
  CaptureRegisterLogs = 19,
  OnOffCycles = 20,
  StartPortLogging = 21,
  StopPortLogging = 22,
}

const NavigationBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [expandDrawer, setExpandDrawer] = useState(false);
  const [expandNotificationDrawer, setExpandNotificationDrawer] = useState(false);
  const navigate = useNavigate();
  const prevControlModeAction = useAppSelector((state) => state.assetData.previousControlModeAction);
  const groupName = useAppSelector((state) => state.assetGroups?.selectedGroup?.assetGroupName);

  const handleClickOutside = () => {
    setExpandDrawer(false);
  };
  const ref = useClickOutside(handleClickOutside);

  useEffect(() => {
    const timerId = setInterval(() => {
      console.log('Refresh token called');
      if (!AuthService.refreshTokenFromServer()) {
        AuthService.logout();
        navigate('/login');
      }
    }, 900000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const getPrevMode = (currentControlActionValue: number) => {
    if (!prevControlModeAction || currentControlActionValue === prevControlModeAction.controlActionType) return 'Mode';
    const actionValue = prevControlModeAction.controlActionType;
    let prevMessage = '';
    switch (Number(actionValue)) {
      case Action.ConstantRunMode:
        prevMessage = `Constant run mode`;
        break;
      case Action.PocMode:
        prevMessage = `POC mode`;
        break;
      case Action.PercentTimerMode:
        prevMessage = `Percent time mode`;
        break;
      default:
        prevMessage = `Mode`;
    }
    return prevMessage;
  };

  const getUpdatedToastMessage = (controlActionType: number, wellName = 'well') => {
    let returnMessage = '';
    switch (controlActionType) {
      case Action.StartWell:
        returnMessage = `${wellName} successfully started`;
        break;
      case Action.StopWell:
        returnMessage = `${wellName} successfully shutdown`;
        break;
      case Action.ClearAlarms:
        returnMessage = `Alarms successfully cleared for ${wellName}`;
        break;
      case Action.SetClock:
        returnMessage = `Clocks successfully reset for ${wellName}`;
        break;
      case Action.ConstantRunMode:
        returnMessage = `${getPrevMode(
          Action.ConstantRunMode,
        )} was successfully changed to Constant run mode for ${wellName}`;
        break;
      case Action.PocMode:
        returnMessage = `${getPrevMode(Action.PocMode)} was successfully changed to POC mode for ${wellName}`;
        break;
      case Action.PercentTimerMode:
        returnMessage = `${getPrevMode(
          Action.PercentTimerMode,
        )} was successfully changed to Percent time mode for ${wellName}`;
        break;
      default:
        returnMessage = `Transaction updated successfully for ${wellName ?? 'well'}`;
    }
    return returnMessage;
  };

  useEffect(() => {
    const webSocketURI = getWebSocketURI('wellcontrolws');
    const socket = new WebSocket(webSocketURI);
    // Connection opened
    socket.addEventListener('open', (event) => {
      console.log('socket open');
      socket.send('Connection established' + event);
    });
    // Listen for messages
    socket.addEventListener('message', (event) => {
      if (event.data.startsWith('socketId:')) {
        dispatch(
          updateSocketId({
            socketId: event.data.split(':')[1],
          }),
        );
      } else if (event.data === 'Close') {
        socket.close();
      } else {
        const parsedData = JSON.parse(event.data);
        if (parsedData.Status == 'Success') {
          const payloadData = JSON.parse(parsedData.Payload);
          if (payloadData && payloadData?.Value) {
            const payloadDetails = JSON.parse(payloadData?.Value?.Payload);
            const nodeId = payloadDetails?.NodeId || payloadDetails?.DeviceScanData?.NodeId;
            dispatch(
              setExecuteControlActionOnReturnSuccessful({
                message: getUpdatedToastMessage(Number(payloadData.Value?.ActionType), nodeId),
                data: parsedData.Payload,
              }),
            );
          }
        }
        if (parsedData.StatusMessage == 'Well updated successfully') {
          const notifications: WellControlNotification[] = [];
          notifications.push(JSON.parse(parsedData.Payload));
          dispatch(
            addNotificationsFromWellControlTransaction({
              notifications: notifications,
            }),
          );
        } else if (parsedData.StatusMessage == 'Transaction updated successfully') {
          const payload = JSON.parse(parsedData.Payload);
          const payloadType = payload.Value.PayloadType;
          const payload2 = JSON.parse(payload.Value.Payload);
          switch (payloadType) {
            case 2: {
              if (
                payload2.LogEventDataList === null ||
                (payload2.LogEventDataList.length === 1 && payload2.LogEventDataList[0] === null)
              )
                return;

              const notifications: WellControlNotification[] = payload2.LogEventDataList;
              dispatch(
                addNotificationsFromWellControlTransaction({
                  notifications: notifications,
                }),
              );
              break;
            }
          }
        } else {
          if (parsedData.StatusMessage) {
            dispatch(
              setExecuteControlActionOnReturnError({
                message: groupName ? `Transaction failed for ${groupName}` : parsedData.StatusMessage,
              }),
            );
          }
        }
      }
    });
  }, []);
  const handleAssetListClick = () => {
    const shouldDisplayDrawer = !expandDrawer;
    setExpandDrawer(shouldDisplayDrawer);
    if (expandNotificationDrawer) {
      setExpandNotificationDrawer(!expandNotificationDrawer);
    }
    dispatch(
      updateSearchGroups({
        groupId: undefined,
        assetId: undefined,
        searchString: '',
        assetIdGuid: undefined,
      }),
    );
  };

  const handleNotificationClick = () => {
    const shouldDisplayDrawer = !expandNotificationDrawer;
    setExpandNotificationDrawer(shouldDisplayDrawer);
    if (expandDrawer) {
      setExpandDrawer(!expandDrawer);
    }
  };
  const handleLogoutClick = () => {
    dispatch(setInitialCustomViewState());
    dispatch(setInitialTrendLibraryState());
    dispatch(setInitialCoordinateState());
    dispatch(updateCheckBoxFlag(false));
    dispatch(setInitialCustomViews());
    dispatch(setInitialState());

    startTransition(() => {
      AuthService.logout();
      navigate('/login');
    });
  };

  return (
    <div ref={ref} className='navigation-bar-container'>
      <div className='navigation-bar-top-container'>
        <div className='navigation-bar-navigation-section-container'>
          <Link to='/' className='navigation-bar-logo-image-container'>
            <img id='xspoc-logo-image' src={ConnexiaLogo} alt='XSPOC Image' className='navigation-bar-logo-image' />
          </Link>
          <div className='navigation-bar-buttons-container'>
            <Tooltip content='Asset List' direction='right'>
              <div
                data-testid='group-and-assets-image'
                className={`navigation-bar-navigation-image-container ${expandDrawer ? 'clicked' : ''}`}
                onClick={handleAssetListClick}
              >
                <AssetListIcon width='24' height='24' stroke='#F4FBFC' />
              </div>
            </Tooltip>
            <Tooltip content='Notifications' direction='right'>
              <div
                data-testid='notifications-image'
                className={`navigation-bar-navigation-image-container ${expandNotificationDrawer ? 'clicked' : ''}`}
                onClick={handleNotificationClick}
              >
                <NotificationIcon width='24' height='24' stroke='#F4FBFC' />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className='navigation-bar-slide-out-section-container'>
          {AuthService.isUserLoggedIn() && (
            <Tooltip content='Logout' direction='right'>
              <div
                data-testid='collapse-icon'
                className={`navigation-bar-navigation-image-container`}
                onClick={handleLogoutClick}
              >
                <CollapseIcon width='24' height='24' stroke='#F4FBFC' />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div>{expandDrawer && <NavigationDrawerItem />}</div>
      <div>{expandNotificationDrawer && <NotificationDrawerItem />}</div>
    </div>
  );
};

export default NavigationBar;

const apiMap = {
  api: process.env.REACT_APP_WEB_API_BASE_URL,
  wellcontrolapi: process.env.REACT_APP_WELLCONTROL_API_BASE_URL,
  setpointapi: process.env.REACT_APP_SETPOINT_API_BASE_URL,
  usersapi: process.env.REACT_APP_USER_API_BASE_URL,
};

const webSocketMap = {
  ws: process.env.REACT_APP_WEB_SOCKET_URL,
  wellcontrolws: process.env.REACT_APP_WELLCONTROL_WEB_SOCKET_URL,
};

const getWebSocketBaseURL = (id = 'ws') => {
  const lowercasedId = id.toLowerCase();
  const baseUrl = webSocketMap[lowercasedId];

  if (baseUrl) {
    return `${baseUrl}/${lowercasedId}`;
  }

  return webSocketMap['ws'];
};

const convertWindowProtocolToWebSocket = (id: string) => {
  const host = window.location.host;
  return window.location.protocol === 'https:' ? `wss://${host}/${id}` : `ws://${host}/${id}`;
};

export const getAPIBaseURL = (id = 'api') => {
  const lowercasedId = id.toLowerCase();
  const baseUrl = apiMap[lowercasedId];

  if (baseUrl) {
    return `${baseUrl}/${lowercasedId}`;
  }

  return `/${lowercasedId}`;
};

export const getWebSocketURI = (id: string) => {
  const wellControlWebSocketURI = getWebSocketBaseURL(id) ?? '';

  if (wellControlWebSocketURI !== '') {
    return wellControlWebSocketURI;
  } else {
    return convertWindowProtocolToWebSocket(id);
  }
};

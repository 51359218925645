import React, { useEffect, useRef } from 'react';
import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { createYAxisLabelDefault } from '../../../utilities/ChartDefaults';
import { getCardColor } from './../../../utilities/CardColorGenerator';
import { TrendType, ChartDataType, ChartTrendType, TrendGroupViewChartProps } from './TrendChartModal';
import { useAppSelector } from '../../../hooks/storeHooks';
import './TrendGroupChart.scss';

export const defaultCurveColors = [
  {
    color: '#005F7C',
  },
  {
    color: '#6BBBAE',
  },
  {
    color: '#F68D2E',
  },
  {
    color: '#FFD072',
  },
  {
    color: '#0094BD',
  },
  {
    color: '#3A7E73',
  },
  {
    color: '#00857D',
  },
  {
    color: '#FFBF3F',
  },
];

const epochDateTimeMapper = (trends: TrendType[]) => {
  if (!trends) return;
  const formattedTrendGroups: ChartTrendType[] = [];
  trends.map((trendGroup) => {
    if (!trendGroup || !trendGroup.coordinates) return;
    const newCoordinates = trendGroup.coordinates.map((dateValue) => {
      const formattedDate = new Date(dateValue.x).getTime();
      return { date: formattedDate, value: dateValue.y };
    });
    formattedTrendGroups.push({ name: trendGroup.name, coordinates: newCoordinates });
  });
  return formattedTrendGroups;
};

const TrendGroupChart: React.FC<TrendGroupViewChartProps> = ({
  selectedAlarmLimits,
  showAlarmLimits,
  trendName,
  trendGroup,
  endDate,
  startDate,
}) => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const trendGroupsArray = epochDateTimeMapper(trendGroup);
    if (!rootRef.current) return;

    const root = am5core.Root.new(rootRef.current);

    const container = am5core.Container.new(root, {
      width: am5core.percent(100),
      height: am5core.percent(100),
      layout: root.verticalLayout,
    });

    root.container.children.push(container);

    function addChart() {
      const chart = am5xy.XYChart.new(root, {
        panY: true,
        panX: true,
        wheelY: 'zoomX',
        layout: root.horizontalLayout,
        maxTooltipDistance: 0,
      });

      container.children.push(chart);

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          groupData: true,
          baseInterval: {
            timeUnit: 'minute',
            count: 1,
          },
          markUnitChange: true,
          renderer: am5xy.AxisRendererX.new(root, {
            strokeWidth: 1,
            strokeOpacity: 1,
            stroke: am5core.color('#B8C5CC'),
            minGridDistance: 100,
            minorGridEnabled: true,
          }),
          tooltip: am5core.Tooltip.new(root, {}),
        }),
      );

      const xAxisRenderer = xAxis.get('renderer');

      xAxisRenderer.grid.template.setAll({
        strokeOpacity: 0,
      });

      xAxisRenderer.labels.template.setAll({
        fontFamily: 'Mulish',
        fontSize: '12px',
        fontWeight: '400',
        paddingTop: 10,
        fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
        minPosition: 0.05,
        maxPosition: 0.95,
      });

      xAxisRenderer.ticks.template.setAll({
        stroke: am5core.color('#FFFFFF'),
        strokeOpacity: 1,
        visible: true,
        minPosition: 0.05,
        maxPosition: 0.95,
      });

      if (xAxis.get('tooltipDateFormats')) {
        xAxis.get('tooltipDateFormats')!['millisecond'] = 'MM/dd/yyyy';
        xAxis.get('tooltipDateFormats')!['second'] = 'MM/dd/yyyy';
        xAxis.get('tooltipDateFormats')!['minute'] = 'MM/dd/yyyy';
        xAxis.get('tooltipDateFormats')!['hour'] = 'MM/dd/yyyy';
        xAxis.get('tooltipDateFormats')!['day'] = 'MM/dd/yyyy';
        xAxis.get('tooltipDateFormats')!['week'] = 'MM/dd/yyyy';
        xAxis.get('tooltipDateFormats')!['month'] = 'MM/dd/yyyy';
        xAxis.get('tooltipDateFormats')!['year'] = 'MM/dd/yyyy';
      }

      if (xAxis.get('dateFormats')) {
        xAxis.get('dateFormats')!['second'] = 'hh:mm:ss a';
        xAxis.get('dateFormats')!['minute'] = 'hh:mm a';
        xAxis.get('dateFormats')!['hour'] = 'hh:mm a';
        xAxis.get('dateFormats')!['day'] = 'MMM dd';
        xAxis.get('dateFormats')!['week'] = 'MMM dd';
        xAxis.get('dateFormats')!['month'] = 'MMM dd';
        xAxis.get('dateFormats')!['year'] = 'MMM dd';
      }

      if (xAxis.get('periodChangeDateFormats')) {
        xAxis.get('periodChangeDateFormats')!['hour'] = 'MMM dd hh:mm a';
      }

      function createAxisAndSeries(index: number, seriesName: string) {
        if (index > 5) {
          return;
        }
        const opposite = index % 2 !== 0;
        const randomColor = getCardColor(index);

        const yRenderer = am5xy.AxisRendererY.new(root, {
          opposite: opposite,
        });

        yRenderer.labels.template.setAll({
          rotation: -90,
          paddingRight: -15,
          fontFamily: 'Mulish',
          fontSize: '12px',
          fontWeight: '400',
        });
        yRenderer.grid.template.setAll({
          stroke: am5core.color('#E7ECEE'),
          strokeOpacity: 1,
          fill: am5core.color('#FFEA00'),
        });

        const yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            maxDeviation: 1,
            renderer: yRenderer,
          }),
        );

        if (chart.yAxes.indexOf(yAxis) > 0) {
          yAxis.set('syncWithAxis', chart?.yAxes?.getIndex(0));
        }

        const tooltip = am5core.Tooltip.new(root, {
          getFillFromSprite: false,
          getLabelFillFromSprite: false,
          autoTextColor: false,
        });

        const series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: seriesName,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueXField: 'date',
            tooltip: tooltip,
            stroke:
              defaultCurveColors[index] && defaultCurveColors[index].color
                ? am5core.color(defaultCurveColors[index].color)
                : am5core.color(`rgb(${randomColor.r}, ${randomColor.g}, ${randomColor.b})`),
          }),
        );

        series.get('tooltip').label.adapters.add('html', function (text) {
          text = '';
          let index = 0;
          chart.series.each(function (series) {
            const tooltipDataItem = series.get('tooltipDataItem');

            if (tooltipDataItem) {
              if (index != 0) {
                text += '\n';
              }

              text +=
                '<div  class="trend-label-item-data"  >' +
                '<span class="trend-label-item-color" style="border-left: 2px solid ' +
                defaultCurveColors[index % defaultCurveColors.length].color +
                '"></span>' +
                '<div   class="trend-group-label-container">' +
                '<p class="left-align">' +
                series.get('name') +
                '</p>' +
                '<p class="right-align">' +
                tooltipDataItem.get('valueY') +
                '</p>' +
                '</div>' +
                '</div>';
            }

            index++;
          });

          const tooltipDataItem = series.get('tooltipDataItem');
          if (tooltipDataItem) {
            //  const start = tooltipDataItem.get('valueY'); ; // Assuming 'valueX' holds a timestamp
            const formattedDate = root.dateFormatter.format(
              new Date(tooltipDataItem.get('valueX')),
              'EEEE, MMM d, HH:mm a',
            );
            return (
              formattedDate +
              '<div style="border-top: 1px solid #4A5463;margin-top:10px;padding-top:10px"' +
              text +
              '</div>'
            );
          }
          // return text;
          // const start = dataItem.get('valueX');
        });
        tooltip.get('background').setAll({
          fill: am5core.color('#001023'),
          stroke: am5core.color('#4A5463'),
          fillOpacity: 1,
        });

        series.get('tooltip').label.adapters.add('text', function (text) {
          text = '';
          let index = 0;
          chart.series.each(function (series) {
            const tooltipDataItem = series.get('tooltipDataItem');
            if (tooltipDataItem) {
              if (index != 0) {
                text += '\n';
              }
              text += series.get('name') + ':' + tooltipDataItem.get('valueY');
            }
            index++;
          });
          return text;
        });

        const yAxisLabelValue = createYAxisLabelDefault(
          root,
          seriesName,
          isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
        );

        yAxis.children.unshift(yAxisLabelValue);
        series.strokes.template.setAll({ strokeWidth: 1 });

        yRenderer.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

        series.data.processor = am5core.DataProcessor.new(root, {
          dateFormat: 'yyyy-MM-dd',
          dateFields: ['date'],
        });
        series.bullets.push(function () {
          // create the circle first
          const circle = am5core.Circle.new(root, {
            radius: 6,
            stroke: am5core.color(0xffffff),
            strokeWidth: 4,
            interactive: true, //required to trigger the state on hover
            fill: am5core.color(0x191f23),
            opacity: 0,
          });

          circle.states.create('default', {
            opacity: 0,
          });

          circle.states.create('hover', {
            opacity: 1,
          });

          return am5core.Bullet.new(root, {
            sprite: circle,
          });
        });

        if (trendGroupsArray && trendGroupsArray.length > 0) {
          series.data.setAll(trendGroupsArray[index].coordinates);
        }

        if (showAlarmLimits && selectedAlarmLimits.values.length > 0) {
          renderAlarmLimits(seriesName, xAxis, yAxis, root, chart);
        }
      }

      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          behavior: 'none',
        }),
      );

      cursor.lineX.set('stroke', am5core.color('#60BFDA'));
      cursor.lineY.set('stroke', am5core.color('#60BFDA'));
      cursor.lineY.set('strokeWidth', 2);
      cursor.lineX.set('strokeWidth', 2);
      cursor.lineY.set('visible', false);
      cursor.setAll({
        xAxis: xAxis,
      });
      cursor.events.on('cursormoved', cursorMoved);

      let previousBulletSprites = [];
      function cursorMoved() {
        for (let i = 0; i < previousBulletSprites.length; i++) {
          previousBulletSprites[i].unhover();
        }
        previousBulletSprites = [];
        chart.series.each(function (series) {
          const dataItem = series.get('tooltip').dataItem;
          if (dataItem && dataItem?.bullets && dataItem?.bullets.length) {
            const bulletSprite = dataItem?.bullets[0].get('sprite');
            bulletSprite.hover();
            previousBulletSprites.push(bulletSprite);
          }
        });
      }

      if (trendGroupsArray) {
        trendGroupsArray.forEach((value, index) => {
          createAxisAndSeries(index, value.name);
        });
      }

      chart.appear(1000, 100);
    }

    if (trendName !== '') {
      addChart();
    }
    root.setThemes([am5themes_Animated.new(root)]);

    return () => {
      root.dispose();
    };
  }, [trendName, trendGroup, selectedAlarmLimits, showAlarmLimits]);

  const renderAlarmLimits = (seriesName, xAxis, yAxis, root, chart) => {
    selectedAlarmLimits.values.forEach((limit) => {
      const loChartData: ChartDataType[] = [];
      const loLoChartData: ChartDataType[] = [];
      const hiChartData: ChartDataType[] = [];
      const hiHiChartData: ChartDataType[] = [];

      const epochStartDate = new Date(startDate).getTime();
      const epochEndDate = new Date(endDate).getTime();

      loChartData.push({ date: epochStartDate, value: limit.loLimit });
      loChartData.push({ date: epochEndDate, value: limit.loLimit });
      loLoChartData.push({ date: epochStartDate, value: limit.loLoLimit });
      loLoChartData.push({ date: epochEndDate, value: limit.loLoLimit });

      hiChartData.push({ date: epochStartDate, value: limit.hiLimit });
      hiChartData.push({ date: epochEndDate, value: limit.hiLimit });
      hiHiChartData.push({ date: epochStartDate, value: limit.hiHiLimit });
      hiHiChartData.push({ date: epochEndDate, value: limit.hiHiLimit });

      const series = am5xy.LineSeries.new(root, {
        name: seriesName,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5core.Tooltip.new(root, {}),
      });

      const hiSeries = am5xy.LineSeries.new(root, {
        name: seriesName,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5core.Tooltip.new(root, {}),
      });

      const loLoSeries = am5xy.LineSeries.new(root, {
        name: seriesName,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5core.Tooltip.new(root, {}),
      });

      const hiHiSeries = am5xy.LineSeries.new(root, {
        name: seriesName,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5core.Tooltip.new(root, {}),
      });
      series.strokes.template.set('strokeWidth', 2);

      hiSeries.data.setAll(hiChartData);
      hiSeries.appear(2000, 100);
      hiSeries.strokes.template.set('strokeWidth', 2);

      loLoSeries.data.setAll(loLoChartData);
      loLoSeries.appear(2000, 100);
      loLoSeries.strokes.template.set('strokeWidth', 2);
      loLoSeries.strokes.template.set('strokeDasharray', [2, 2]);

      hiHiSeries.data.setAll(hiHiChartData);
      hiHiSeries.appear(2000, 100);
      hiHiSeries.strokes.template.set('strokeWidth', 2);
      hiHiSeries.strokes.template.set('strokeDasharray', [2, 2]);

      series.data.setAll(loChartData);
      series.appear(2000, 100);

      chart.series.push(hiSeries);
      chart.series.push(loLoSeries);
      chart.series.push(hiHiSeries);
      chart.series.push(series);
    });
  };

  return <div ref={rootRef} className={trendName ? 'trend-group-view-chart' : 'trend-group-chart'}></div>;
};

export default TrendGroupChart;

import api from '../../../services/api';

export const fetchDataHistoryAlarmLimits = async (selectedAssetId, address) => {
  try {
    const apiEndpoint = `DataHistory/AlarmLimits?assetId=${selectedAssetId}&addresses=${address}`;

    return await api
      .get(apiEndpoint, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return {};
  }
};

export const fetchDataHistoryTrendViews = async () => {
  try {
    const apiEndpoint = `/DataHistory/GetDefaultTrendViews`;

    return await api
      .get(apiEndpoint, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return {};
  }
};

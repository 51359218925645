import { configureStore } from '@reduxjs/toolkit';
import assetSlice from './features/asset/AssetDataSlice';
import rodPumpWellStatusReducer from './features/asset-status/AssetStatusSlice';
import uiButtonToolbarReducer from './features/navigation/UIButtonToolbarSlice';
import assetGroupReducer from './features/navigation/AssetGroupsSlice';
import cardDateSlice from './features/rod-lift-analysis/CardDateSlice';
import cardCoordinatesSlice from './features/rod-lift-analysis/CardCoordinatesSlice';
import analysisSlice from './features/rod-lift-analysis/AnalysisSlice';
import notificationSlice from './features/navigation/NotificationsSlice';
import notificationsTypesSlice from './features/navigation/NotificationTypesSlice';
import tableViewSlice from './features/group-status/TableViewSlice';
import groupStatusSlice from './features/group-status/GroupStatusSlice';
import groupStatusWidgetSlice from './features/group-status/GroupStatusWidgets/GroupStatusWidgetSlice.tsx';
import espAnalysisSlice from './features/esp-analysis/ESPAnalysisSlice';
import espCardCoordinatesSlice from './features/esp-analysis/ESPCardCoordinatesSlice';
import espWellTestSlice from './features/esp-analysis/WellTestSlice';
import glAnalysisSlice from './features/gl-analysis/GLAnalysisSlice';
import gLAnalysisSurveyDateSlice from './features/gl-analysis/GLAnalysisSurveyDateSlice';
import glAnalysisWellTestSlice from './features/gl-analysis/GLAnalysisWellTestSlice';
import notificationFilterReducer from './features/navigation/NotificationFilterSlice';
import viewOptionsReducer from './features/common/ViewOptions/ViewOptionsSlice';
import AssetAnalysisSlice from './features/asset-analysis/AssetAnalysisSlice';
import signInDefaultViewSlice from './onboarding/default-view/SignInDefaultViewSlice';
import glAnalysisSurveyCurveCoordinatesSlice from './features/gl-analysis/GLAnalysisSurveyCurveCoordinatesSlice';
import glAnalysisCurveCoordinatesSlice from './features/gl-analysis/GLAnalysisCurveCoordinatesSlice';
import trendLibrarySlice from './features/data-history/TrendLibrary/TrendLibrarySlice';
import trendItemSlice from './features/data-history/TrendLibrary/TrendCoordinateSlice.tsx';
import TrendGroupLabelSlice from './features/asset-analysis/TrendGroupLabel/TrendGroupLabelSlice.tsx';
import themeSlice from './layouts/ThemeSlice.ts';
import CustomViewDataSlice from './features/data-history/TrendLibrary/DefaultTrendsSlice.tsx';

export const store = configureStore({
  reducer: {
    assetData: assetSlice,
    rodliftwellstatus: rodPumpWellStatusReducer,
    uiButtons: uiButtonToolbarReducer,
    assetGroups: assetGroupReducer,
    notificationButtons: uiButtonToolbarReducer,
    notificationType: notificationsTypesSlice,
    rodLiftAnalysis: cardDateSlice,
    rodLiftAnalysisCardCoordinates: cardCoordinatesSlice,
    rodLiftAnalysisAnalysis: analysisSlice,
    notificationsList: notificationSlice,
    tableView: tableViewSlice,
    groupStatus: groupStatusSlice,
    groupStatusWidget: groupStatusWidgetSlice,
    espAnalysisWellTest: espWellTestSlice,
    espAnalysisResult: espAnalysisSlice,
    glAnalysisData: glAnalysisSlice,
    gLAnalysisSurveyDate: gLAnalysisSurveyDateSlice,
    glAnalysisWellTest: glAnalysisWellTestSlice,
    notificationFilter: notificationFilterReducer,
    viewOptions: viewOptionsReducer,
    AssetAnalysis: AssetAnalysisSlice,
    signInDefaultView: signInDefaultViewSlice,
    glAnalysisSurveyCurveCoordinates: glAnalysisSurveyCurveCoordinatesSlice,
    espAnalysisCardCoordinates: espCardCoordinatesSlice,
    glAnalysisCurveCoordinates: glAnalysisCurveCoordinatesSlice,
    trendLibrary: trendLibrarySlice,
    trendItem: trendItemSlice,
    trendGroupLabel: TrendGroupLabelSlice,
    theme: themeSlice,
    customViewDataSlice: CustomViewDataSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

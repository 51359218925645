import React, { useEffect, useRef } from 'react';
import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import './TrendGroupViewChart.scss';
import { createYAxisLabelDefault } from '../../../utilities/ChartDefaults';
import {
  TrendType,
  ChartTrendType,
  TrendGroupViewChartProps,
} from './../../../features/asset-analysis/TrendGroupLabel/TrendChartModal';
import { getCardColor } from './../../../utilities/CardColorGenerator';
import { useAppSelector } from '../../../hooks/storeHooks';

export const defaultCurveColors = [
  {
    color: '#FEC84B',
  },
  {
    color: '#FB743C',
  },
  {
    color: '#60BFDA',
  },
  {
    color: '#7ABFBA',
  },
  {
    color: '#4294FF',
  },
  {
    color: '#F97066',
  },
  {
    color: '#FFFFFF',
  },
  {
    color: '#B8D7FF',
  },
];

const epochDateTimeMapper = (trends: TrendType[]) => {
  if (!trends) return;
  const formattedTrendGroups: ChartTrendType[] = [];
  trends.map((trendGroup) => {
    if (!trendGroup || !trendGroup.coordinates) return;
    const newCoordinates = trendGroup.coordinates.map((dateValue) => {
      const formattedDate = new Date(dateValue.x).getTime();
      return { date: formattedDate, value: dateValue.y };
    });
    formattedTrendGroups.push({ name: trendGroup.name, coordinates: newCoordinates, color: trendGroup.color });
  });
  return formattedTrendGroups;
};

const chartsToSync: any[] = [];
const trendViewTooltip: any = null;

const TrendGroupViewChart: React.FC<TrendGroupViewChartProps> = ({
  trendName,
  trendGroup,
  onTooltipDataChanged,
  startDate,
  endDate,
  chartIndex,
  maxElements,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';

  useEffect(() => {
    const trendGroupsArray = epochDateTimeMapper(trendGroup);
    if (!rootRef.current) return;
    const root = am5core.Root.new(rootRef.current);

    const container = am5core.Container.new(root, {
      width: am5core.percent(100),
      height: am5core.percent(100),
      layout: root.verticalLayout,
    });

    root.container.children.push(container);

    const chart = am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      layout: root.horizontalLayout,
      maxTooltipDistance: 0,
    });
    container.children.push(chart);
    chartsToSync.push(chart);

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: true,
        baseInterval: { timeUnit: 'minute', count: 1 },
        markUnitChange: true,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeWidth: 1,
          strokeOpacity: 1,
          stroke: am5core.color('#B8C5CC'),
          minGridDistance: 100,
          minorGridEnabled: true,
        }),
        tooltip: am5core.Tooltip.new(root, {
          keepTargetHover: true,
          forceHidden: true,
        }),
        strictMinMax: true,
        min: startDate?.getTime(),
        max: endDate?.getTime(),
      }),
    );

    const xAxisRenderer = xAxis.get('renderer');

    xAxisRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    xAxisRenderer.labels.template.setAll({
      fontFamily: 'Mulish',
      fontSize: '12px',
      fontWeight: '400',
      paddingTop: 10,
      fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      minPosition: 0.04,
      maxPosition: 0.96,
    });

    xAxisRenderer.ticks.template.setAll({
      stroke: am5core.color('#FFFFFF'),
      strokeOpacity: 1,
      visible: true,
      minPosition: 0.04,
      maxPosition: 0.96,
    });

    if (xAxis.get('tooltipDateFormats')) {
      xAxis.get('tooltipDateFormats')!['millisecond'] = 'EEEE, MMM d, hh:mm a';
      xAxis.get('tooltipDateFormats')!['second'] = 'EEEE, MMM d, hh:mm a';
      xAxis.get('tooltipDateFormats')!['minute'] = 'EEEE, MMM d, hh:mm a';
      xAxis.get('tooltipDateFormats')!['hour'] = 'EEEE, MMM d, hh:mm a';
      xAxis.get('tooltipDateFormats')!['day'] = 'EEEE, MMM d, hh:mm a';
      xAxis.get('tooltipDateFormats')!['week'] = 'EEEE, MMM d, hh:mm a';
      xAxis.get('tooltipDateFormats')!['month'] = 'EEEE, MMM d, hh:mm a';
      xAxis.get('tooltipDateFormats')!['year'] = 'EEEE, MMM d, hh:mm a';
    }

    if (xAxis.get('dateFormats')) {
      xAxis.get('dateFormats')!['second'] = 'hh:mm:ss a';
      xAxis.get('dateFormats')!['minute'] = 'hh:mm a';
      xAxis.get('dateFormats')!['hour'] = 'hh:mm a';
      xAxis.get('dateFormats')!['day'] = 'MMM dd';
      xAxis.get('dateFormats')!['week'] = 'MMM dd';
      xAxis.get('dateFormats')!['month'] = 'MMM dd';
      xAxis.get('dateFormats')!['year'] = 'MMM dd';
    }

    if (xAxis.get('periodChangeDateFormats')) {
      xAxis.get('periodChangeDateFormats')!['hour'] = 'MMM dd hh:mm a';
    }

    const xAxesToolTip = am5core.Tooltip.new(root, {
      keepTargetHover: true,
    });
    xAxesToolTip.get('background').set('fill', am5core.color('#001023'));
    xAxesToolTip.get('background').set('stroke', am5core.color('#001023'));
    xAxesToolTip.get('background').set('cornerRadius', 4);

    const xAxis2Renderer = am5xy.AxisRendererX.new(root, {
      strokeWidth: 0,
      opposite: true,
      minGridDistance: 100,
      minorGridEnabled: true,
    });

    chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        baseInterval: { timeUnit: 'minute', count: 1 },
        markUnitChange: false,
        renderer: xAxis2Renderer,
        tooltip: xAxesToolTip,
        strictMinMax: true,
        min: startDate?.getTime(),
        max: endDate?.getTime(),
      }),
    );

    xAxis2Renderer.labels.template.setAll({ visible: true, fill: am5core.color('#1a2737') });

    function createAxisAndSeries(index: number, seriesName: string, isEmptyAxes: boolean | undefined) {
      if (index > 3) return;
      if (chartIndex !== 0) {
        xAxesToolTip.set('forceHidden', true);
      } else {
        xAxesToolTip.set('forceHidden', false);
      }
      const opposite = index % 2 !== 0;
      const randomColor = getCardColor(index);

      const yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite,
      });

      yRenderer.labels.template.setAll({
        rotation: -90,
        paddingRight: -15,
        fontFamily: 'Mulish',
        fontSize: '12px',
        fontWeight: '400',
      });
      yRenderer.grid.template.setAll({
        stroke: am5core.color('#E7ECEE'),
        strokeOpacity: isDarkMode ? 0.1 : 1,
        fill: am5core.color('#FFEA00'),
      });

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: yRenderer,
        }),
      );
      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set('syncWithAxis', chart?.yAxes?.getIndex(0));
      }

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: isEmptyAxes ? seriesName : '',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5core.Tooltip.new(root, {
            keepTargetHover: true,
            forceHidden: true,
          }),
          stroke:
            defaultCurveColors[index] && defaultCurveColors[index].color
              ? am5core.color(defaultCurveColors[index].color)
              : am5core.color(`rgb(${randomColor.r}, ${randomColor.g}, ${randomColor.b})`),
        }),
      );

      series.get('tooltip').label.adapters.add('html', function () {
        chart.series.each(function (series) {
          const tooltipDataItem = series.get('tooltipDataItem');
          if (tooltipDataItem) {
            onTooltipDataChanged(series.get('name') + ':' + tooltipDataItem.get('valueY'));
          }

          index++;
        });
      });

      const yAxisLabelValue = createYAxisLabelDefault(
        root,
        seriesName,
        isDarkMode
          ? am5core.color('#F7F9F9')
          : defaultCurveColors[index] && defaultCurveColors[index].color
          ? am5core.color(defaultCurveColors[index].color)
          : am5core.color(`rgb(${randomColor.r}, ${randomColor.g}, ${randomColor.b})`),
      );

      yAxis.children.unshift(yAxisLabelValue);
      series.strokes.template.setAll({ strokeWidth: 1 });

      const colorVar = isEmptyAxes ? am5core.color('#F7F9F9') : am5core.color('#1a2737');
      yRenderer.labels.template.set('fill', colorVar);

      series.data.processor = am5core.DataProcessor.new(root, {
        dateFormat: 'yyyy-MM-dd',
        dateFields: ['date'],
      });
      series.bullets.push(function () {
        // create the circle first
        const circle = am5core.Circle.new(root, {
          radius: 6,
          stroke: am5core.color(0xffffff),
          strokeWidth: 4,
          interactive: true, //required to trigger the state on hover
          fill: am5core.color(0x191f23),
          opacity: 0,
        });

        circle.states.create('default', {
          opacity: 0,
        });

        circle.states.create('hover', {
          opacity: 1,
        });

        return am5core.Bullet.new(root, {
          sprite: circle,
        });
      });

      if (trendGroupsArray && trendGroupsArray.length > 0) {
        const coordinates = trendGroupsArray[index]?.coordinates;
        if (coordinates && coordinates.length > 0) {
          series.data.setAll(coordinates);
        } else {
          series.data.setAll([{ date: 0, value: 0 }]);
          yRenderer.labels.template.setAll({
            visible: false,
          });
        }
      } else {
        series.data.setAll([{ date: 0, value: 0 }]);
        yRenderer.labels.template.setAll({
          visible: false,
        });
      }
    }

    // Add cursor
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: 'none',
      }),
    );
    cursor.lineX.set('stroke', am5core.color('#60BFDA'));
    cursor.lineY.set('stroke', am5core.color('#60BFDA'));
    cursor.lineY.set('strokeWidth', 2);
    cursor.lineX.set('strokeWidth', 2);
    cursor.lineY.set('visible', false);
    cursor.setAll({
      xAxis: xAxis,
    });
    cursor.events.on('cursormoved', cursorMoved);

    let previousBulletSprites = [];
    function cursorMoved() {
      for (let i = 0; i < previousBulletSprites.length; i++) {
        previousBulletSprites[i].unhover();
      }
      previousBulletSprites = [];
      chart.series.each(function (series) {
        const dataItem = series.get('tooltip').dataItem;
        if (dataItem && dataItem?.bullets && dataItem?.bullets.length) {
          const bulletSprite = dataItem?.bullets[0].get('sprite');
          bulletSprite.hover();
          previousBulletSprites.push(bulletSprite);
        }
      });
    }

    if (trendGroupsArray && trendGroupsArray.length > 0) {
      const chartDataArray: any[] = [];
      if (trendGroupsArray.length < maxElements) {
        for (let i = 0; i < maxElements; i++) {
          if (trendGroupsArray[i]) {
            chartDataArray.push(trendGroupsArray[i]);
          } else {
            chartDataArray.push({ name: ` `, coordinates: [] });
          }
        }
        chartDataArray.forEach((trendGroup, index) => {
          createAxisAndSeries(index, trendGroup.name, true);
        });
      } else {
        trendGroupsArray.forEach((trendGroup, index) => {
          createAxisAndSeries(index, trendGroup.name, true);
        });
      }
    } else {
      if (maxElements > 0) {
        for (let i = 0; i < maxElements; i++) {
          createAxisAndSeries(i, ` `, false);
        }
      }
    }

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [trendName, trendGroup, themeMode]);

  // Synchronize cursors
  useEffect(() => {
    if (chartsToSync.length > 1) {
      am5core.array.each(chartsToSync, function (sourceChart) {
        const syncCursors = [];
        am5core.array.each(chartsToSync, function (targetChart) {
          if (targetChart !== sourceChart) {
            syncCursors.push(targetChart.get('cursor'));
          }
        });
        sourceChart.get('cursor').set('syncWith', syncCursors, { axis: sourceChart.xAxes.getIndex(0) });
      });
    }
  }, [trendName, trendGroup]);

  return <div ref={rootRef} className='trend-group-view-chart'></div>;
};

export { trendViewTooltip };

export default TrendGroupViewChart;

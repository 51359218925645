import api from '../../services/api';

export interface FetchNotificationTypeResponseValues {
  Id: number;
  Name: string;
}

export interface FetchNotificationTypeResponse {
  Id: string;
  DateCreated: string;
  Values: FetchNotificationTypeResponseValues[];
}

const fetchNotificationTypeNameAsync = async () => {
  try {
    return await api
      .get('notificationTypes', {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data as FetchNotificationTypeResponse;
        } else {
          console.error('Unexpected response format:', response.data);
        }
      });
  } catch (error) {
    console.error('Error fetching notification list:', error);
    return [];
  }
};

const fetchNotificationsInitialState = async (notificationURL: string) => {
  try {
    return await api
      .get(notificationURL, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching notification list:', error);
    return [];
  }
};

export { fetchNotificationTypeNameAsync, fetchNotificationsInitialState };
